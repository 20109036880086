import type { Spacings } from '../types/types';

const spacings: Spacings = {
  0: 'var(--fp-spacing-0)',
  1: 'var(--fp-spacing-1)',
  2: 'var(--fp-spacing-2)',
  3: 'var(--fp-spacing-3)',
  4: 'var(--fp-spacing-4)',
  5: 'var(--fp-spacing-5)',
  6: 'var(--fp-spacing-6)',
  7: 'var(--fp-spacing-7)',
  8: 'var(--fp-spacing-8)',
  9: 'var(--fp-spacing-9)',
  10: 'var(--fp-spacing-10)',
  11: 'var(--fp-spacing-11)',
  12: 'var(--fp-spacing-12)',
  13: 'var(--fp-spacing-13)',
  14: 'var(--fp-spacing-14)',
  15: 'var(--fp-spacing-15)',
};

export default spacings;
