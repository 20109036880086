// Same as IdentityDataKind on backend
enum UserDataAttribute {
  firstName = 'first_name',
  lastName = 'last_name',
  email = 'email',
  phoneNumber = 'phone_number',
  dob = 'dob',
  ssn9 = 'ssn9',
  ssn4 = 'ssn4',
  addressLine1 = 'address_line1',
  addressLine2 = 'address_line2',
  city = 'city',
  state = 'state',
  country = 'country',
  zip = 'zip',
}

export default UserDataAttribute;
