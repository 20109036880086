import React from 'react';

import type { FlagProps } from '../types';

const FlagLs = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)" fillRule="evenodd" clipRule="evenodd">
      <path d="M0 10h20v5H0v-5z" fill="#55BA07" />
      <path d="M0 5h20v5H0V5z" fill="#F7FCFF" />
      <path d="M0 0h20v5H0V0z" fill="#3D58DB" />
      <path
        d="M9.531 5.15c-.326.088-.499.314-.499.713 0 .457.224.885.5 1.106V5.15zm.781 1.807c.268-.224.483-.645.483-1.093 0-.388-.17-.612-.482-.706v1.799zm-.026-2.124c.512.113.882.462.882 1.028 0 .411-.196.912-.5 1.255l1.522 1.492.375-.124.56.85S11.709 10 9.958 10c-1.75 0-3.083-.666-3.083-.666l.477-.726.385.126 1.411-1.592c-.316-.344-.522-.86-.522-1.281 0-.586.396-.94.936-1.04a.39.39 0 0 1 .724.012zM7.501 8.935l-.158.22h.308l-.15-.22zm.18.307-.272.055.273.095v-.15zm.14-.317.139.325.346-.094-.069-.13-.416-.1zm.752.649-.508-.1-.058-.098.383-.084.183.282zm.03-.466.158.284.347-.105-.097-.128-.408-.05zm.284.522-.062-.117.384-.103.222.27-.544-.05zm.718-.195.495-.13-.06-.113h-.644l.209.243zm.177.25-.078-.121.475-.156.253.278h-.65zm.602-.484.152.186.412-.143-.052-.097-.512.054zm.894.387-.554.061-.081-.137.418-.138.217.214zm-.035-.477.155.19.372-.19-.104-.058-.423.058zm.321.426-.067-.103.354-.213.18.213-.467.103zm.351-.593.225.214.219-.214-.07-.088-.373.088zm.357.287.183-.18.131.18-.314.102V9.23z"
        fill="#1D1D1D"
      />
    </g>
  </svg>
);
export default FlagLs;
