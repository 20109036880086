import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoActivity16 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        d="M1.75 7.784h3.017L6.491 1.75l3.018 12.5 1.724-6.466h3.017"
        stroke={theme.color[color]}
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IcoActivity16;
