import React from 'react';

import type { FlagProps } from '../types';

const FlagLb = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)" fillRule="evenodd" clipRule="evenodd">
      <path d="M20 0H0v3.75h20V0zm0 11.25H0V15h20v-3.75z" fill="#F50101" />
      <path d="M0 3.75h20v7.5H0v-7.5z" fill="#F7FCFF" />
      <path
        d="M13.447 7.82c.046.543-1.954-.224-1.954-.007s2.143.598 2.14 1.084c-.004.486-2.542-.139-1.973.08.57.218 1.791.741 1.652 1.067-.296.696-2.266-.483-2.43-.25-.264.375 1.454 1.249 1.44 1.333-.029.16-1.174-.252-1.44.08-.215-.06-.314-.35-.381-.388l-.423-5.452s.38 5.133-.105 5.28c-.485.148-1.832.597-1.872.373-.014-.084 1.622-.851 1.358-1.226-.195-.276-2.17.703-2.296.136-.561-.405 1.818-.755 1.818-1.169 0-.414-2.176.49-1.916-.086.26-.576 2.003-.782 2.035-.862.06-.146-1.937.227-1.937 0 0-.43 1.195-.514 1.937-.901.044-.442-1.8.434-1.937.08-.137-.353 2.357-1.27 2.296-1.341-.096-.113-2.106.396-1.775.128.643-.518 1.9-1.223 2.188-1.412 0 0 .035-.07.072-.046.037-.025.078-.05.078-.05s0 .443-.006.096c-.006-.346 2.944 1.17 2.977 1.554.039.449-1.822-.338-1.779.103.379.234 1.455.688 1.9.888.201.09-1.486-.192-1.45-.053.09.353 1.734.396 1.783.962z"
        fill="#52AB0B"
      />
    </g>
  </svg>
);
export default FlagLb;
