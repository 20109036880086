import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoDatabase24 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53 7c.037.042.106.11.237.202.293.203.76.423 1.386.623C8.4 8.222 10.133 8.5 12 8.5c1.867 0 3.601-.278 4.847-.675.627-.2 1.093-.42 1.386-.623.13-.091.2-.16.236-.202a1.23 1.23 0 0 0-.236-.202c-.293-.203-.76-.423-1.386-.623C15.6 5.778 13.867 5.5 12 5.5c-1.867 0-3.601.278-4.847.675-.627.2-1.093.42-1.386.623-.13.091-.2.16-.236.202Zm1.167-2.254C8.11 4.296 10.001 4 12 4c2 0 3.89.295 5.303.746.702.225 1.323.499 1.786.82.422.294.911.769.911 1.434v10c0 .666-.49 1.14-.91 1.433-.463.322-1.085.596-1.787.82C15.89 19.706 13.999 20 12 20c-2 0-3.89-.295-5.303-.746-.702-.225-1.324-.499-1.786-.82C4.489 18.14 4 17.665 4 17V7c0-.665.49-1.14.91-1.433.463-.322 1.085-.596 1.787-.82ZM5.5 8.778v3.18c.016.027.076.11.267.244.293.204.76.422 1.386.623 1.246.397 2.98.675 4.847.675 1.867 0 3.601-.278 4.847-.675.627-.2 1.093-.42 1.386-.623.191-.133.25-.217.267-.243V8.777c-.355.178-.76.336-1.197.476C15.89 9.704 13.999 10 12 10c-2 0-3.89-.295-5.303-.746A8.46 8.46 0 0 1 5.5 8.778Zm13 5a8.47 8.47 0 0 1-1.197.476C15.89 14.704 13.999 15 12 15c-2 0-3.89-.295-5.303-.746a8.464 8.464 0 0 1-1.197-.476v3.18c.016.027.076.11.267.244.293.203.76.422 1.386.623 1.246.397 2.98.675 4.847.675 1.867 0 3.601-.278 4.847-.675.627-.2 1.093-.42 1.386-.623.191-.133.25-.217.267-.244v-3.18Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoDatabase24;
