import React from 'react';

import type { FlagProps } from '../types';

const FlagAn = ({ className, testID }: FlagProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={11}
    fill="none"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#fff" d="M0 0h16v10.667H0V0Z" />
      <path fill="#DC171D" d="M6.222 0h3.556v10.667H6.222V0Z" />
      <path fill="#012A87" d="M0 3.556h16V7.11H0V3.556Z" />
      <path fill="#fff" d="m8 3.704-.137.422.21.07L8 3.703Z" />
      <path fill="#fff" d="m8 3.704.137.422-.211.07L8 3.703Z" />
      <path fill="#fff" d="M8.423 4.01h-.445v.223l.445-.222Z" />
      <path fill="#fff" d="m8.423 4.01-.36.262-.13-.18.49-.081Z" />
      <path fill="#fff" d="m7.577 4.01.36.262.13-.18-.49-.081Z" />
      <path fill="#fff" d="M7.577 4.01h.445v.223l-.445-.222Z" />
      <path fill="#fff" d="m8.261 4.508-.137-.423-.212.069.35.354Z" />
      <path fill="#fff" d="m8.261 4.508-.36-.261.131-.18.23.44Z" />
      <path fill="#fff" d="m7.739 4.508.36-.262-.131-.18-.23.442Z" />
      <path
        fill="#fff"
        d="m7.739 4.508.137-.423.211.069-.348.354Zm2.631.178-.137.422.211.069-.074-.491Z"
      />
      <path fill="#fff" d="m10.37 4.686.138.422-.212.069.074-.491Z" />
      <path fill="#fff" d="M10.793 4.993h-.444v.222l.444-.222Z" />
      <path fill="#fff" d="m10.793 4.993-.36.26-.13-.179.49-.081Z" />
      <path fill="#fff" d="m9.948 4.993.36.26.13-.179-.49-.081Z" />
      <path fill="#fff" d="M9.948 4.993h.444v.222l-.444-.222Z" />
      <path fill="#fff" d="m10.632 5.49-.138-.423-.211.069.349.354Z" />
      <path fill="#fff" d="m10.632 5.49-.36-.262.13-.18.23.442Z" />
      <path fill="#fff" d="m10.11 5.49.359-.262-.13-.18-.23.441Z" />
      <path
        fill="#fff"
        d="m10.11 5.49.136-.423.212.068-.349.354Zm-1.128.584-.138.423.212.068-.074-.49Z"
      />
      <path fill="#fff" d="m8.982 6.074.137.423-.211.068.074-.49Z" />
      <path fill="#fff" d="M9.405 6.381H8.96v.222l.445-.222Z" />
      <path fill="#fff" d="m9.405 6.381-.36.261-.13-.18.49-.08Z" />
      <path fill="#fff" d="m8.56 6.381.359.261.13-.18-.49-.08Z" />
      <path fill="#fff" d="M8.56 6.381h.444v.222l-.445-.222Z" />
      <path fill="#fff" d="m9.243 6.878-.137-.423-.212.07.349.353Z" />
      <path fill="#fff" d="m9.243 6.878-.36-.261.131-.18.229.441Z" />
      <path fill="#fff" d="m8.72 6.878.36-.261-.13-.18-.23.441Z" />
      <path
        fill="#fff"
        d="m8.72 6.878.138-.423.211.069-.348.354ZM5.63 4.686l.137.422-.211.069.074-.491Z"
      />
      <path fill="#fff" d="m5.63 4.686-.138.422.212.069-.074-.491Z" />
      <path fill="#fff" d="M5.207 4.993h.444v.222l-.444-.222Z" />
      <path fill="#fff" d="m5.207 4.993.36.26.13-.179-.49-.081Z" />
      <path fill="#fff" d="m6.052 4.993-.36.26-.13-.179.49-.081Z" />
      <path fill="#fff" d="M6.052 4.993h-.444v.222l.444-.222Z" />
      <path fill="#fff" d="m5.368 5.49.138-.423.211.069-.349.354Z" />
      <path fill="#fff" d="m5.368 5.49.36-.262-.13-.18-.23.442Z" />
      <path fill="#fff" d="m5.89 5.49-.359-.262.13-.18.23.441Z" />
      <path
        fill="#fff"
        d="m5.89 5.49-.136-.423-.212.068.349.354Zm1.128.584.138.423-.212.068.074-.49Z"
      />
      <path fill="#fff" d="m7.018 6.074-.137.423.211.068-.074-.49Z" />
      <path fill="#fff" d="M6.595 6.381h.445v.222l-.445-.222Z" />
      <path fill="#fff" d="m6.595 6.381.36.261.13-.18-.49-.08Z" />
      <path fill="#fff" d="m7.44 6.381-.359.261-.13-.18.49-.08Z" />
      <path fill="#fff" d="M7.44 6.381h-.444v.222l.445-.222Z" />
      <path fill="#fff" d="m6.757 6.878.137-.423.212.07-.349.353Z" />
      <path fill="#fff" d="m6.757 6.878.36-.261-.131-.18-.229.441Z" />
      <path fill="#fff" d="m7.28 6.878-.36-.261.13-.18.23.441Z" />
      <path fill="#fff" d="m7.28 6.878-.138-.423-.211.069.348.354Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h16v10.667H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FlagAn;
