import React from 'react';

import type { FlagProps } from '../types';

const FlagKi = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#E31D1C"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15h20V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m9.577 10.736.286-.62.286.62.17-.662.393.56.049-.68.487.48-.075-.677.566.387-.196-.654.627.28-.31-.609.667.165-.415-.543.686.044-.506-.462.683-.078-.581-.365.658-.198-.638-.255.612-.312-.673-.138.546-.415-.688-.017.463-.505-.68.105.365-.579-.65.224.254-.634-.599.335.136-.669-.529.436.014-.682-.442.523-.11-.674-.34.593-.23-.644-.229.644-.34-.593-.11.674-.442-.523.013.682-.529-.436.136.67-.599-.336.254.634-.65-.224.365.579-.68-.105.463.505-.688.017.547.415-.674.138.612.312-.638.255.659.198-.582.365.684.078-.507.462.686-.044-.415.543.668-.165-.311.61.627-.281-.196.654.566-.387-.075.678.488-.481.048.68.393-.56.17.662z"
          fill="#FFC84A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m-.495 8.7 1.634-.663a.976.976 0 0 1 .743.003l2.269.944c.257.107.547.1.797-.02l1.83-.88a.976.976 0 0 1 .833-.005l1.917.885c.258.12.556.12.814.002l1.962-.894a.976.976 0 0 1 .814.002l1.937.894c.255.117.547.12.803.007l2.116-.932a.98.98 0 0 1 .724-.025l1.898.682v6.259H-.551L-.495 8.7z"
          fill="#F7FCFF"
        />
        <mask
          id="prefix__c"
          maskUnits="userSpaceOnUse"
          x={-1}
          y={7}
          width={22}
          height={8}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m-.495 8.7 1.634-.663a.976.976 0 0 1 .743.003l2.269.944c.257.107.547.1.797-.02l1.83-.88a.976.976 0 0 1 .833-.005l1.917.885c.258.12.556.12.814.002l1.962-.894a.976.976 0 0 1 .814.002l1.937.894c.255.117.547.12.803.007l2.116-.932a.98.98 0 0 1 .724-.025l1.898.682v6.259H-.551L-.495 8.7z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#prefix__c)">
          <path
            d="m-.495 10.154 1.634-.664a.976.976 0 0 1 .743.004l2.269.944c.257.107.547.1.797-.021l1.83-.879a.976.976 0 0 1 .833-.006l1.917.886c.258.12.556.12.814.002l1.962-.895a.976.976 0 0 1 .814.002l1.937.894c.255.118.547.12.803.008l2.116-.932a.98.98 0 0 1 .724-.026l1.898.683m-21.091 2.08 1.634-.663a.977.977 0 0 1 .743.003l2.269.945a.976.976 0 0 0 .797-.022l1.83-.878a.976.976 0 0 1 .833-.006l1.917.886c.258.119.556.12.814.002l1.962-.895a.976.976 0 0 1 .814.002l1.937.894c.255.117.547.12.803.007l2.116-.932a.98.98 0 0 1 .724-.025l1.898.682"
            stroke="#015989"
            strokeWidth={1.25}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m-.495 13.892.682-.277a3.477 3.477 0 0 1 2.643.012l1.32.55a.978.978 0 0 0 .798-.022l.77-.369a3.476 3.476 0 0 1 2.961-.022l.849.392c.258.12.556.12.814.002l.918-.418a3.476 3.476 0 0 1 2.9.006l.895.414c.255.117.547.12.803.007l1.203-.53a3.476 3.476 0 0 1 2.577-.09l.958.345v2.072H-.38l-.115-2.072z"
            fill="#015989"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.113 2.137h1.162c.293 0 .954-.448 1.336-.3.382.15 1.026.632 1.026.632s1.062-.838 1.695-.838c.634 0 1.777-.094 1.777-.094s-.268.696-1.023.814c-.754.118-1.656.696-1.656.696h1.226c.187 0 .43.281.43.281s-.502.342-.754.342h-.528s-.534.458-1.193.36c-.66-.097-1.189-.702-1.189-.702s-.577-.082-.577-.182c0 0-.356-.182-.463 0-.107.182.463-.244.463-.244s.228-.214.403-.214c.174 0 .756.358.756.358l.14-.144-.722-.55c-1.47.153-2.31-.215-2.31-.215z"
          fill="#FFC84A"
        />
      </g>
    </g>
  </svg>
);
export default FlagKi;
