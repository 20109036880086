/**
 * Do not edit directly
 * Generated on Tue, 01 Nov 2022 16:36:34 GMT
 */

export const primitivesBrandSleep = '#0e1438';
export const primitivesBrandThink = '#cbc1f6';
export const primitivesBrandGo = '#76fb8f';
export const primitivesGray0 = '#ffffff';
export const primitivesGray50 = '#f7f7f7';
export const primitivesGray100 = '#e2e2e2';
export const primitivesGray150 = '#d4d4d4';
export const primitivesGray200 = '#c6c6c6';
export const primitivesGray300 = '#a9a9a9';
export const primitivesGray400 = '#8d8d8d';
export const primitivesGray500 = '#707070';
export const primitivesGray600 = '#5a5a5a';
export const primitivesGray700 = '#444444';
export const primitivesGray800 = '#2d2d2d';
export const primitivesGray900 = '#161616';
export const primitivesGray1000 = '#000000';
export const primitivesRed50 = '#FFF2F0';
export const primitivesRed100 = '#edd0ce';
export const primitivesRed200 = '#e5a19d';
export const primitivesRed300 = '#d9726c';
export const primitivesRed400 = '#cc433b';
export const primitivesRed500 = '#bf140a';
export const primitivesRed600 = '#991008';
export const primitivesRed700 = '#730c06';
export const primitivesRed800 = '#4c0804';
export const primitivesYellow50 = '#fff8eb';
export const primitivesYellow100 = '#fff2d9';
export const primitivesYellow200 = '#fee5b5';
export const primitivesYellow300 = '#ffd582';
export const primitivesYellow400 = '#ffc759';
export const primitivesYellow500 = '#ffa617';
export const primitivesYellow600 = '#f28900';
export const primitivesYellow700 = '#ba5d00';
export const primitivesYellow800 = '#9b4e00';
export const primitivesGreen50 = '#e9f5f1';
export const primitivesGreen100 = '#cee6de';
export const primitivesGreen200 = '#9ecebe';
export const primitivesGreen300 = '#6db59d';
export const primitivesGreen400 = '#3d9d7d';
export const primitivesGreen500 = '#0c845c';
export const primitivesGreen600 = '#0a6a4a';
export const primitivesGreen700 = '#074f37';
export const primitivesGreen800 = '#053525';
export const primitivesBlue50 = '#edf3fc';
export const primitivesBlue100 = '#cee2f9';
export const primitivesBlue200 = '#9ec5f3';
export const primitivesBlue300 = '#6da7ee';
export const primitivesBlue400 = '#3d8ae8';
export const primitivesBlue500 = '#0c6de2';
export const primitivesBlue600 = '#0a57b5';
export const primitivesBlue700 = '#074188';
export const primitivesBlue800 = '#052c5a';
export const primitivesPurple50 = '#F5F2FC';
export const primitivesPurple100 = '#ebe7fb';
export const primitivesPurple200 = '#c3b6f3';
export const primitivesPurple300 = '#9b85eb';
export const primitivesPurple400 = '#7255e3';
export const primitivesPurple500 = '#4a24db';
export const primitivesPurple600 = '#3a1caa';
export const primitivesPurple700 = '#29147a';
export const primitivesPurple800 = '#190c49';
export const primitivesDarkblue50 = '#e4e6ec';
export const primitivesDarkblue100 = '#bbc0d1';
export const primitivesDarkblue200 = '#9098b2';
export const primitivesDarkblue300 = '#677194';
export const primitivesDarkblue400 = '#4a5580';
export const primitivesDarkblue500 = '#2c3b6d';
export const primitivesDarkblue600 = '#273466';
export const primitivesDarkblue700 = '#1f2c5b';
export const primitivesDarkblue800 = '#18234f';
export const primitivesDarkblue900 = '#0e1438';
export const primitivesStaticBlack = '#000000';
export const primitivesStaticDimGray = '#444444';
export const primitivesStaticDarkGray = '#707070';
export const primitivesStaticLightGray = '#c6c6c6';
export const primitivesStaticWhite = '#ffffff';
export const primitivesStaticSleep = '#0e1438';
export const primitivesStaticThink = '#cbc1f6';
export const primitivesStaticGo = '#76fb8f';
export const primitivesElevation1 = '#FFFFFF';
export const primitivesElevation2 = '#FFFFFF';
export const primitivesElevation3 = '#FFFFFF';
export const semanticBgPrimary = '#ffffff';
export const semanticBgSecondary = '#f7f7f7';
export const semanticBgTertiary = '#0e1438';
export const semanticBgQuaternary = '#cbc1f6';
export const semanticBgQuinary = '#76fb8f';
export const semanticBgSenary = '#e2e2e2';
export const semanticBgInfo = '#edf3fc';
export const semanticBgError = '#FFF2F0';
export const semanticBgWarning = '#fff8eb';
export const semanticBgSuccess = '#e9f5f1';
export const semanticBgAccent = '#4a24db';
export const semanticBgNeutral = '#f7f7f7';
export const semanticBgSurface1 = '#FFFFFF';
export const semanticBgSurface2 = '#FFFFFF';
export const semanticBgSurface3 = '#FFFFFF';
export const semanticTextPrimary = '#000000';
export const semanticTextSecondary = '#2d2d2d';
export const semanticTextTertiary = '#707070';
export const semanticTextQuaternary = '#8d8d8d';
export const semanticTextQuinary = '#ffffff';
export const semanticTextSenary = '#cbc1f6';
export const semanticTextSeptenary = '#76fb8f';
export const semanticTextInfo = '#0a57b5';
export const semanticTextError = '#991008';
export const semanticTextWarning = '#9b4e00';
export const semanticTextSuccess = '#0a6a4a';
export const semanticTextAccent = '#4a24db';
export const semanticTextNeutral = '#2d2d2d';
export const semanticBorderPrimary = '#d4d4d4';
export const semanticBorderSecondary = '#4a24db';
export const semanticBorderTertiary = '#e2e2e2';
export const semanticBorderError = '#bf140a';
export const elevation0 = '0px 0px 0px 0px rgb(0, 0, 0)';
export const elevation1 = '0px 1px 4px 0px rgba(0, 0, 0, 0.12156862745098039)';
export const elevation2 = '0px 1px 8px 0px rgba(0, 0, 0, 0.1411764705882353)';
export const elevation3 = '0px 1px 12px 0px rgba(0, 0, 0, 0.1803921568627451)';
export const elevationDialog =
  '0px 1px 8px 0px rgba(0, 0, 0, 0.1411764705882353)';
export const fontFamilyDefault = 'DM Sans';
export const fontFamilyCode = 'Source Code Pro';
export const typographyDisplay1 = '600 60px/76px "DM Sans"';
export const typographyDisplay2 = '600 40px/52px "DM Sans"';
export const typographyDisplay3 = '600 28px/36px "DM Sans"';
export const typographyDisplay4 = '400 21px/32px "DM Sans"';
export const typographyHeading1 = '600 24px/32px "DM Sans"';
export const typographyHeading2 = '600 21px/32px "DM Sans"';
export const typographyHeading3 = '600 19px/28px "DM Sans"';
export const typographyBody1 = '400 17px/28px "DM Sans"';
export const typographyBody2 = '400 16px/24px "DM Sans"';
export const typographyBody3 = '400 15px/20px "DM Sans"';
export const typographyBody4 = '400 14px/20px "DM Sans"';
export const typographyLabel1 = '500 17px/28px "DM Sans"';
export const typographyLabel2 = '500 16px/24px "DM Sans"';
export const typographyLabel3 = '500 15px/20px "DM Sans"';
export const typographyLabel4 = '500 14px/20px "DM Sans"';
export const typographyCaption1 = '500 13px/16px "DM Sans"';
export const typographyCaption2 = '400 13px/16px "DM Sans"';
export const typographyCaption3 = '500 12px/16px "DM Sans"';
export const typographyCaption4 = '400 12px/16px "DM Sans"';
export const typographySnippet1 = '400 16px/24px "Source Code Pro"';
export const typographySnippet2 = '400 14px/20px "Source Code Pro"';
export const typographyHeaderTitle = '500 16px/24px "DM Sans"';
export const typographyBodyTitle = '600 19px/28px "DM Sans"';
export const typographyBodySubtitle = '400 16px/24px "DM Sans"';
export const typographyBodyLabelDefault = '500 16px/24px "DM Sans"';
export const typographyBodyLabelCompact = '500 15px/20px "DM Sans"';
export const typographyBodyBodyDefault = '400 16px/24px "DM Sans"';
export const typographyBodyBodyCompact = '400 15px/20px "DM Sans"';
export const typographyBodyBodyTiny = '400 14px/20px "DM Sans"';
export const borderRadiusFull = 100;
export const borderRadiusLarge = 16;
export const borderRadiusDefault = 6;
export const borderRadiusCompact = 4;
export const borderRadiusDialog = 6;
export const spacing0 = 0;
export const spacing1 = 2;
export const spacing2 = 4;
export const spacing3 = 8;
export const spacing4 = 12;
export const spacing5 = 16;
export const spacing6 = 20;
export const spacing7 = 24;
export const spacing8 = 32;
export const spacing9 = 40;
export const spacing10 = 64;
export const spacing11 = 96;
export const spacing12 = 128;
export const spacing13 = 156;
export const spacing14 = 192;
export const spacing15 = 256;
export const opacity100 = 0.04;
export const opacity200 = 0.08;
export const opacity300 = 0.12;
export const opacity400 = 0.16;
export const opacity500 = 0.24;
export const opacity600 = 0.32;
export const opacity700 = 0.48;
export const opacity800 = 0.64;
export const opacity900 = 0.8;
export const opacity1000 = 1;
export const opacityTransparent = 0;
export const borderWidthDefault = 1;
export const selectIconInactive = '#000000';
export const selectIconFocus = '#4a24db';
export const selectIconFocusError = '#991008';
export const phoneInputsIconInactive = '#8d8d8d';
export const phoneInputsIconFocus = '#4a24db';
export const phoneInputsIconFocusError = '#991008';
export const linkButtonDefaultInitialText = '#4a24db';
export const linkButtonDefaultInitialIcon = '#4a24db';
export const linkButtonDefaultHoverText = '#29147a';
export const linkButtonDefaultHoverIcon = '#29147a';
export const linkButtonDefaultActiveText = '#29147a';
export const linkButtonDefaultActiveIcon = '#29147a';
export const linkButtonDefaultDisabledText = '#190c4952';
export const linkButtonDefaultDisabledIcon = '#190c4952';
export const linkButtonDestructiveInitialText = '#991008';
export const linkButtonDestructiveInitialIcon = '#991008';
export const linkButtonDestructiveHoverText = '#4c0804';
export const linkButtonDestructiveHoverIcon = '#4c0804';
export const linkButtonDestructiveActiveText = '#4c0804';
export const linkButtonDestructiveActiveIcon = '#4c0804';
export const linkButtonDestructiveDisabledText = '#4c080452';
export const linkButtonDestructiveDisabledIcon = '#4c080452';
export const linkButtonSizingDefault = 24;
export const linkButtonSizingCompact = 20;
export const linkButtonSizingTiny = 20;
export const linkButtonSizingXTiny = 16;
export const linkButtonSizingXxTiny = 16;
export const linkButtonTypographyDefault = '500 16px/24px "DM Sans"';
export const linkButtonTypographyCompact = '500 15px/20px "DM Sans"';
export const linkButtonTypographyTiny = '500 14px/20px "DM Sans"';
export const linkButtonTypographyXTiny = '500 13px/16px "DM Sans"';
export const linkButtonTypographyXxTiny = '500 12px/16px "DM Sans"';
export const iconButtonBorderWidth = 1;
export const iconButtonHeightLarge = 40;
export const iconButtonHeightDefault = 32;
export const iconButtonBorderRadius = 100;
export const iconButtonTransparentInitialBg = '#ffffff00';
export const iconButtonTransparentInitialIcon = '#000000';
export const iconButtonTransparentInitialBorder = '#e2e2e200';
export const iconButtonTransparentHoverBg = '#f7f7f7';
export const iconButtonTransparentHoverIcon = '#000000';
export const iconButtonTransparentHoverBorder = '#e2e2e200';
export const iconButtonTransparentActiveBg = '#e2e2e2';
export const iconButtonTransparentActiveIcon = '#000000';
export const iconButtonTransparentActiveBorder = '#e2e2e200';
export const iconButtonTransparentDisabledBg = '#f7f7f7';
export const iconButtonTransparentDisabledIcon = '#8d8d8d';
export const iconButtonTransparentDisabledBorder = '#e2e2e200';
export const iconButtonElevation = '0px 0px 0px 0px rgb(0, 0, 0)';
export const iconButtonSolidInitialBg = '#ffffff';
export const iconButtonSolidInitialIcon = '#000000';
export const iconButtonSolidInitialBorder = '#e2e2e2';
export const iconButtonSolidHoverBg = '#f7f7f7';
export const iconButtonSolidHoverIcon = '#000000';
export const iconButtonSolidHoverBorder = '#e2e2e2';
export const iconButtonSolidActiveBg = '#e2e2e2';
export const iconButtonSolidActiveIcon = '#000000';
export const iconButtonSolidActiveBorder = '#e2e2e2';
export const iconButtonSolidDisabledBg = '#f7f7f7';
export const iconButtonSolidDisabledIcon = '#8d8d8d';
export const iconButtonSolidDisabledBorder = '#e2e2e2';
export const googleButtonInitialBg = '#ffffff';
export const googleButtonInitialText = '#000000';
export const googleButtonInitialBorder = '#d4d4d4';
export const googleButtonHoverBg = '#f7f7f7';
export const googleButtonHoverText = '#000000';
export const googleButtonHoverBorder = '#d4d4d4';
export const googleButtonActiveBg = '#f7f7f7';
export const googleButtonActiveText = '#000000';
export const googleButtonActiveBorder = '#d4d4d4';
export const googleButtonLoadingBg = '#ffffff';
export const googleButtonLoadingIcon = '#000000';
export const googleButtonLoadingBorder = '#d4d4d4';
export const googleButtonHeightLarge = 60;
export const googleButtonHeightDefault = 48;
export const googleButtonHeightCompact = 40;
export const googleButtonBorderRadius = 6;
export const googleButtonSpacingPaddingsHorizontalLarge = 24;
export const googleButtonSpacingPaddingsHorizontalDefault = 24;
export const googleButtonSpacingPaddingsHorizontalCompact = 24;
export const googleButtonTypographyLarge = '500 17px/28px "DM Sans"';
export const googleButtonTypographyDefault = '500 16px/24px "DM Sans"';
export const googleButtonTypographyCompact = '500 15px/20px "DM Sans"';
export const buttonBorderWidth = '1px';
export const buttonElevationInitial = '0px 0px 0px 0px rgb(0, 0, 0)';
export const buttonElevationHover = '0px 0px 0px 0px rgb(0, 0, 0)';
export const buttonElevationActive = '0px 0px 0px 0px rgb(0, 0, 0)';
export const fpButtonInitialBg = '#0e1438';
export const fpButtonInitialText = '#ffffff';
export const fpButtonInitialIcon = '#76fb8f';
export const fpButtonHoverBg = '#18234f';
export const fpButtonHoverText = '#ffffff';
export const fpButtonHoverIcon = '#76fb8f';
export const fpButtonActiveBg = '#18234f';
export const fpButtonActiveText = '#ffffff';
export const fpButtonActiveIcon = '#76fb8f';
export const fpButtonLoadingBg = '#0e1438';
export const fpButtonLoadingIcon = '#ffffff';
export const fpButtonHeight = 48;
export const fpButtonBorderRadius = 6;
export const fpButtonSpacingPaddingsHorizontal = 24;
export const fpButtonTypography = '500 17px/28px "DM Sans"';
export const dialogHeaderBg = '#FFFFFF';
export const dialogHeaderText = '#000000';
export const dialogHeaderIcon = '#000000';
export const dialogHeaderBorder = '#e2e2e2';
export const dialogBodyBgPrimary = '#FFFFFF';
export const dialogBodyBgSecondary = '#f7f7f7';
export const dialogBodyTextPrimary = '#000000';
export const dialogBodyTextSecondary = '#2d2d2d';
export const dialogBodyTextTertiary = '#707070';
export const dialogFooterBg = '#f7f7f7';
export const dialogFooterIcon = '#2d2d2d';
export const dialogFooterText = '#2d2d2d';
export const dialogFooterBorder = '#e2e2e2';
export const dateRangeInputColorTopLabel = '#000000';
export const dateRangeInputColorHint = '#707070';
export const dateRangeInputColorHintError = '#991008';
export const dateRangeInputColorInputContentSelectedText = '#ffffff';
export const dateRangeInputColorInputContentSelectedBg = '#4a24db';
export const dateRangeInputColorInputContentUnselectedText = '#000000';
export const dateRangeInputColorIcon = '#000000';
export const confirmationDialogHeaderBg = '#FFFFFF';
export const confirmationDialogHeaderText = '#000000';
export const confirmationDialogHeaderIcon = '#000000';
export const confirmationDialogHeaderBorder = '#e2e2e2';
export const confirmationDialogBodyBg = '#FFFFFF';
export const confirmationDialogBodyText = '#2d2d2d';
export const confirmationDialogFooterBg = '#FFFFFF';
export const confirmationDialogElevation =
  '0px 1px 8px 0px rgba(0, 0, 0, 0.1411764705882353)';
export const confirmationDialogBorderRadius = 6;
export const confirmationDialogTypographyHeaderTitle =
  '500 16px/24px "DM Sans"';
export const confirmationDialogTypographyBodyBodyDefault =
  '400 16px/24px "DM Sans"';
export const buttonsPrimaryInitialBg = '#0e1438';
export const buttonsPrimaryInitialText = '#ffffff';
export const buttonsPrimaryInitialBorder = '#d4d4d400';
export const buttonsPrimaryHoverBg = '#18234f';
export const buttonsPrimaryHoverText = '#ffffff';
export const buttonsPrimaryHoverBorder = '#d4d4d400';
export const buttonsPrimaryActiveBg = '#18234f';
export const buttonsPrimaryActiveText = '#ffffff';
export const buttonsPrimaryActiveBorder = '#d4d4d400';
export const buttonsPrimaryDisabledBg = '#f7f7f7';
export const buttonsPrimaryDisabledText = '#8d8d8d';
export const buttonsPrimaryDisabledBorder = '#d4d4d400';
export const buttonsPrimaryLoadingBg = '#0e1438';
export const buttonsPrimaryLoadingIcon = '#ffffff';
export const buttonsPrimaryLoadingBorder = '#d4d4d400';
export const buttonsSecondaryInitialBg = '#ffffff';
export const buttonsSecondaryInitialText = '#000000';
export const buttonsSecondaryInitialBorder = '#d4d4d4';
export const buttonsSecondaryHoverBg = '#f7f7f7';
export const buttonsSecondaryHoverText = '#000000';
export const buttonsSecondaryHoverBorder = '#d4d4d4';
export const buttonsSecondaryActiveBg = '#f7f7f7';
export const buttonsSecondaryActiveText = '#000000';
export const buttonsSecondaryActiveBorder = '#d4d4d4';
export const buttonsSecondaryDisabledBg = '#ffffff';
export const buttonsSecondaryDisabledText = '#8d8d8d';
export const buttonsSecondaryDisabledBorder = '#e2e2e2';
export const buttonsSecondaryLoadingBg = '#ffffff';
export const buttonsSecondaryLoadingIcon = '#000000';
export const buttonsSecondaryLoadingBorder = '#d4d4d4';
export const buttonsHeightLarge = '60px';
export const buttonsHeightDefault = '48px';
export const buttonsHeightCompact = '40px';
export const buttonsHeightSmall = '32px';
export const buttonsBorderRadius = '6px';
export const buttonsSpacingPaddingsHorizontalLarge = '24px';
export const buttonsSpacingPaddingsHorizontalDefault = '24px';
export const buttonsSpacingPaddingsHorizontalCompact = '24px';
export const buttonsSpacingPaddingsHorizontalSmall = '12px';
export const buttonsTypographyLarge = '500 17px/28px "DM Sans"';
export const buttonsTypographyDefault = '500 16px/24px "DM Sans"';
export const buttonsTypographyCompact = '500 15px/20px "DM Sans"';
export const buttonsTypographySmall = '500 14px/20px "DM Sans"';
export const bifrostDialogHeaderBg = '#FFFFFF';
export const bifrostDialogHeaderText = '#000000';
export const bifrostDialogHeaderIcon = '#000000';
export const bifrostDialogHeaderBorder = '#e2e2e2';
export const bifrostDialogBodyBgPrimary = '#FFFFFF';
export const bifrostDialogBodyBgSecondary = '#f7f7f7';
export const bifrostDialogBodyTextPrimary = '#000000';
export const bifrostDialogBodyTextSecondary = '#2d2d2d';
export const bifrostDialogBodyTextTertiary = '#707070';
export const bifrostDialogFooterBg = '#f7f7f7';
export const bifrostDialogFooterIcon = '#2d2d2d';
export const bifrostDialogFooterText = '#2d2d2d';
export const bifrostDialogFooterBorder = '#e2e2e2';
export const bifrostDialogElevation =
  '0px 1px 4px 0px rgba(0, 0, 0, 0.12156862745098039)';
export const bifrostDialogBorderRadius = 6;
export const bifrostDialogTypographyHeaderTitle = '500 16px/24px "DM Sans"';
export const bifrostDialogTypographyBodyTitle = '600 19px/28px "DM Sans"';
export const bifrostDialogTypographyBodySubtitle = '400 16px/24px "DM Sans"';
export const bifrostDialogTypographyBodyLabelDefault =
  '500 16px/24px "DM Sans"';
export const bifrostDialogTypographyBodyLabelCompact =
  '500 15px/20px "DM Sans"';
export const bifrostDialogTypographyBodyBodyDefault = '400 16px/24px "DM Sans"';
export const bifrostDialogTypographyBodyBodyCompact = '400 15px/20px "DM Sans"';
export const bifrostDialogTypographyBodyBodyTiny = '400 14px/20px "DM Sans"';
export const bifrostDialogTypographyFooterFooter = '500 13px/16px "DM Sans"';
export const baseInputsBaseBg = '#ffffff';
export const baseInputsBaseBgHover = '#ffffff';
export const baseInputsBaseBgDisabled = '#f7f7f7';
export const baseInputsBaseBorder = '#d4d4d4';
export const baseInputsBaseBorderHover = '#8d8d8d';
export const baseInputsBaseBorderFocus = '#4a24db';
export const baseInputsBaseBorderError = '#bf140a';
export const baseInputsBaseBorderErrorHover = '#730c06';
export const baseInputsBaseBorderDisabled = '#e2e2e2';
export const baseInputsBaseInputTextPlaceholder = '#8d8d8d';
export const baseInputsBaseInputText = '#000000';
export const baseInputsBaseInputTextDisabled = '#8d8d8d';
export const baseInputsBaseLabel = '#000000';
export const baseInputsBaseHint = '#707070';
export const baseInputsBaseHintError = '#991008';
export const baseInputsInitialBg = '#ffffff';
export const baseInputsInitialBorder = '#d4d4d4';
export const baseInputsInitialPlaceholder = '#8d8d8d';
export const baseInputsInitialLabel = '#000000';
export const baseInputsInitialHint = '#707070';
export const baseInputsInitialErrorBg = '#ffffff';
export const baseInputsInitialErrorBorder = '#bf140a';
export const baseInputsInitialErrorPlaceholder = '#8d8d8d';
export const baseInputsInitialErrorLabel = '#000000';
export const baseInputsInitialErrorHint = '#991008';
export const baseInputsHoverBg = '#ffffff';
export const baseInputsHoverBorder = '#8d8d8d';
export const baseInputsHoverPlaceholder = '#8d8d8d';
export const baseInputsHoverLabel = '#000000';
export const baseInputsHoverHint = '#707070';
export const baseInputsHoverErrorBg = '#ffffff';
export const baseInputsHoverErrorBorder = '#730c06';
export const baseInputsHoverErrorPlaceholder = '#8d8d8d';
export const baseInputsHoverErrorLabel = '#000000';
export const baseInputsHoverErrorHint = '#991008';
export const baseInputsFocusBg = '#ffffff';
export const baseInputsFocusBorder = '#4a24db';
export const baseInputsFocusPlaceholder = '#8d8d8d';
export const baseInputsFocusLabel = '#000000';
export const baseInputsFocusHint = '#707070';
export const baseInputsFocusCaret = '#000000';
export const baseInputsFocusErrorBg = '#ffffff';
export const baseInputsFocusErrorBorder = '#bf140a';
export const baseInputsFocusErrorPlaceholder = '#8d8d8d';
export const baseInputsFocusErrorLabel = '#000000';
export const baseInputsFocusErrorHint = '#991008';
export const baseInputsFocusErrorCaret = '#000000';
export const baseInputsFocusTypingBg = '#ffffff';
export const baseInputsFocusTypingBorder = '#4a24db';
export const baseInputsFocusTypingInputContent = '#000000';
export const baseInputsFocusTypingLabel = '#000000';
export const baseInputsFocusTypingHint = '#707070';
export const baseInputsFocusTypingCaret = '#000000';
export const baseInputsFocusTypingErrorBg = '#ffffff';
export const baseInputsFocusTypingErrorBorder = '#bf140a';
export const baseInputsFocusTypingErrorInputContent = '#000000';
export const baseInputsFocusTypingErrorLabel = '#000000';
export const baseInputsFocusTypingErrorHint = '#991008';
export const baseInputsFocusTypingErrorCaret = '#000000';
export const baseInputsFilledBg = '#ffffff';
export const baseInputsFilledBorder = '#d4d4d4';
export const baseInputsFilledInputContent = '#000000';
export const baseInputsFilledLabel = '#000000';
export const baseInputsFilledHint = '#707070';
export const baseInputsFilledErrorBg = '#ffffff';
export const baseInputsFilledErrorBorder = '#bf140a';
export const baseInputsFilledErrorInputContent = '#000000';
export const baseInputsFilledErrorLabel = '#000000';
export const baseInputsFilledErrorHint = '#991008';
export const baseInputsDisabledBg = '#f7f7f7';
export const baseInputsDisabledBorder = '#e2e2e2';
export const baseInputsDisabledInputContent = '#8d8d8d';
export const baseInputsDisabledLabel = '#000000';
export const baseInputsDisabledHint = '#707070';
export const baseInputsHeightTextArea = 140;
export const baseInputsHeightDefault = 40;
export const baseInputsHeightCompact = 32;
export const baseInputsBorderRadius = 6;
export const baseInputsSpacingPaddingsHorizontalDefault = 16;
export const baseInputsSpacingPaddingsHorizontalCompact = 16;
export const baseInputsSpacingPaddingsVerticalDefault = 10;
export const baseInputsSpacingPaddingsVerticalCompact = 6;
export const baseInputsTypographyDefaultLabel = '500 14px/20px "DM Sans"';
export const baseInputsTypographyDefaultInputContent =
  '400 15px/20px "DM Sans"';
export const baseInputsTypographyDefaultHint = '500 13px/16px "DM Sans"';
export const baseInputsTypographyCompactLabel = '500 13px/16px "DM Sans"';
export const baseInputsTypographyCompactInputContent =
  '400 14px/20px "DM Sans"';
export const baseInputsTypographyCompactHint = '500 13px/16px "DM Sans"';
export const baseInputsElevationFocus =
  '0px 0px 0px 4px rgba(74, 36, 219, 0.12156862745098039)';
export const baseInputsElevationFocusError =
  '0px 0px 0px 4px rgba(191, 20, 10, 0.12156862745098039)';
export const baseInputsBorderWidth = 1;
export const baseDropdownBaseBgPrimary = '#FFFFFF';
export const baseDropdownBaseBgPrimaryHover = '#f7f7f7';
export const baseDropdownBaseBgAddressFooter = '#f7f7f7';
export const baseDropdownBaseTextPrimary = '#000000';
export const baseDropdownBaseTextSecondary = '#8d8d8d';
export const baseDropdownBaseBorder = '#e2e2e2';
export const baseDropdownBaseIconPrimary = '#000000';
export const baseDropdownBaseIconSecondary = '#8d8d8d';
export const baseDropdownElevation =
  '0px 1px 8px 0px rgba(0, 0, 0, 0.1411764705882353)';
export const baseDropdownSpacingItems2linesPaddingsHorizontal = 16;
export const baseDropdownSpacingItems2linesPaddingsVertical = 4;
export const baseDropdownSpacingItems2linesGap = 4;
export const baseDropdownSpacingItems1linePaddingsHorizontal = 16;
export const baseDropdownSpacingItems1linePaddingsVertical = 8;
export const baseDropdownSpacingItems1lineGapChildContainer = 12;
export const baseDropdownSpacingItems1lineGapParentContainer = 16;
export const baseDropdownSpacingSearchPaddingsHorizontal = 16;
export const baseDropdownSpacingSearchPaddingsVertical = 12;
export const baseDropdownSpacingSearchGapChildContainer = 12;
export const baseDropdownSpacingSearchGapParentContainer = 16;
export const baseDropdownSpacingBodyPaddingsVertical = 8;
export const baseDropdownSpacingBodyItemsGap = 4;
export const baseDropdownSpacingBodySectionsGap = 20;
export const baseDropdownBorderRadius = 6;
export const tokenSetOrder0 = 'global';
export const tokenSetOrder1 = 'light';
export const tokenSetOrder2 = 'dark';
export const tokenSetOrder3 = 'components/date-range-input';
export const tokenSetOrder4 = 'components/fp-button';
export const tokenSetOrder5 = 'components/buttons';
export const tokenSetOrder6 = 'components/google-button';
export const tokenSetOrder7 = 'components/link-button';
export const tokenSetOrder8 = 'components/icon-button';
export const tokenSetOrder9 = 'components/base-inputs';
export const tokenSetOrder10 = 'components/phone-inputs';
export const tokenSetOrder11 = 'components/select';
export const tokenSetOrder12 = 'components/base-dropdown';
export const tokenSetOrder13 = 'components/bifrost-dialog';
export const tokenSetOrder14 = 'components/confirmation-dialog';
export const tokenSetOrder15 = 'components/dialog';
export const tokenSetOrder16 = 'customization/ramp/global';
export const tokenSetOrder17 = 'customization/ramp/light';
export const tokenSetOrder18 = 'customization/ramp/base-inputs';
export const tokenSetOrder19 = 'customization/ramp/buttons';
export const tokenSetOrder20 = 'customization/ramp/bifrost-dialog';
