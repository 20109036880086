import type { Typographies } from '../types/types';

const typographies: Typographies = {
  'display-1': 'var(--fp-typography-display1)',
  'display-2': 'var(--fp-typography-display2)',
  'display-3': 'var(--fp-typography-display3)',
  'display-4': 'var(--fp-typography-display4)',
  'heading-1': 'var(--fp-typography-heading1)',
  'heading-2': 'var(--fp-typography-heading2)',
  'heading-3': 'var(--fp-typography-heading3)',
  'body-1': 'var(--fp-typography-body1)',
  'body-2': 'var(--fp-typography-body2)',
  'body-3': 'var(--fp-typography-body3)',
  'body-4': 'var(--fp-typography-body4)',
  'label-1': 'var(--fp-typography-label1)',
  'label-2': 'var(--fp-typography-label2)',
  'label-3': 'var(--fp-typography-label3)',
  'label-4': 'var(--fp-typography-label4)',
  'caption-1': 'var(--fp-typography-caption1)',
  'caption-2': 'var(--fp-typography-caption2)',
  'caption-3': 'var(--fp-typography-caption3)',
  'caption-4': 'var(--fp-typography-caption4)',
  'snippet-1': 'var(--fp-typography-snippet1)',
  'snippet-2': 'var(--fp-typography-snippet2)',
};

export default typographies;
