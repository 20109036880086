import React from 'react';

import type { FlagProps } from '../types';

const FlagMx = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0h6v15h-6V0z"
        fill="#D9071E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h6v15H0V0z"
        fill="#006923"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0h8v15H6V0z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99 4.946s-.43.333-.349.688c.08.355.926 0 .824-.344-.102-.344-.475-.344-.475-.344z"
        fill="#FCCA3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.335 5.793c-.322 0-.455-.268-.389-.553.035-.152.128-.344.278-.595l.536.322a3.585 3.585 0 0 0-.137.247 1.2 1.2 0 0 1 .313.141c.292.193.4.535.087.789-.061.049-.16.216-.213.377a.74.74 0 0 1 .354.09c.276.162.25.46.04.69-.15.164-.348.296-.536.364-.312.113-.646.07-.646-.33v-.003c0-.016-.002-.044.138-.047m.006 0H8.16c-.238-.004-.289-.13-.319-.354a2.316 2.316 0 0 1-.014-.227l-.003-.076-.003-.074-.001-.016c-.005-.072-.008-.122.153-.122h-.362V5.79h.362c.206 0 .32.07.385.213.036-.065.075-.127.117-.182a.482.482 0 0 0-.14-.03m-.17 1.493h.022-.021zm.568-.395.01-.01a.024.024 0 0 0-.004.004l-.006.006zm-.274.186zm0-.236.002.01a.5.5 0 0 1-.002-.019v.01z"
        fill="#A8AC71"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.041 7.752s.579-1.714-.34-2.6c-.92-.887-1.981-.828-1.981-.828s-.306.227 0 .4c.306.174.177.34.177.34s-.512-.52-.852-.216c-.34.304.318.257.248.486-.07.229-.364 1.245.066 1.757.431.513-.407.402-.236.402.17 0 .774.12.774 0s.21.464.39.464c.18 0 .319-.205.319-.205s.252.205.406.205c.153 0 .689-.134.689-.134l-1.013-.853s.061-.376-.082-.458c-.144-.081 1.151.722 1.252.98.102.26.183.26.183.26z"
        fill="#8F4620"
      />
      <path
        d="M6.917 7.33s.093-.3.194-.32c.087-.019.27.143.27.143.325 1.806 1.111 2.511 2.416 2.511 1.32 0 2.12-.536 2.586-2.202 0 0 .249-.253.336-.225.096.03.215.366.215.366-.535 1.911-1.547 2.762-3.137 2.762-1.605 0-2.507-.962-2.88-3.036z"
        fill="#9FAB2F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.334 8.535s1.878.399 2.619.399c.74 0-.65.604-1.193.604S8.334 8.535 8.334 8.535z"
        fill="#2FC2DC"
      />
      <rect
        x={9.167}
        y={8.813}
        width={1.25}
        height={0.67}
        rx={0.104}
        fill="#F9AA51"
      />
      <path
        d="m7.61 7.585.511-.359c.666.95 1.662 1.261 3.055.935l.142.609c-1.628.381-2.886-.014-3.708-1.185z"
        fill="#259485"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.37 7.719s-.759.4-.506.4 1.242.199 1.05 0c-.193-.2-.544-.4-.544-.4zm-.897-.476s-.144-.417-.444-.417c-.3 0-.216.342-.36.342s.135.24.259.24.545-.165.545-.165z"
        fill="#FCCA3D"
      />
    </g>
  </svg>
);
export default FlagMx;
