import React from 'react';

import type { FlagProps } from '../types';

const FlagSz = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h20v15H0V0z"
        fill="#3D58DB"
      />
      <path
        d="M0 3.125h-.625v8.75h21.25v-8.75H0z"
        fill="#C51918"
        stroke="#FFD018"
        strokeWidth={1.25}
      />
      <path
        d="M4.468 5.832c-.075 0-.135-.17-.135-.379s.06-.378.135-.378H15.35c.075 0 .136.17.136.378 0 .21-.061.379-.136.379H4.468z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.06 5.376.631-.806 1.118.806-1.118.828-.632-.828z"
        fill="#F7FCFF"
      />
      <path
        d="M4.468 8.332c-.075 0-.135-.17-.135-.379s.06-.378.135-.378H15.35c.075 0 .136.17.136.378 0 .21-.061.379-.136.379H4.468z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.06 7.876.631-.806 1.118.806-1.118.828-.632-.828z"
        fill="#F7FCFF"
      />
      <path
        d="M2.716 9.889c-.1 0-.182-.193-.182-.43 0-.238.081-.43.182-.43h14.59c.1 0 .182.192.182.43 0 .237-.081.43-.182.43H2.716z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51 7.533s1.777-3.738 4.317-3.738 4.375 3.738 4.375 3.738-2.241 3.688-4.346 3.688c-2.104 0-4.345-3.688-4.345-3.688z"
        fill="#F7FCFF"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={5}
        y={3}
        width={10}
        height={9}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.51 7.533s1.777-3.738 4.317-3.738 4.375 3.738 4.375 3.738-2.241 3.688-4.346 3.688c-2.104 0-4.345-3.688-4.345-3.688z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M9.953 3.55s-.58.704-.387 1.375c.193.671.644.6.644 1.192 0 .59-.208 1.194 0 1.194.207 0 .28.407.09.684-.19.277-.359.285-.347.735.01.45.995.892.518.892-.476 0-1.105.463-.905.463.2 0 1.246.36 1.246.833v.851H5.357V3.55h4.596z"
          fill="#272727"
        />
        <path
          d="M7.387 6.202h-.272v1.106h.272V6.202zm.8 0h-.27v1.106h.27V6.202zm-.671 0h.271v1.106h-.271V6.202zm1.072 0h-.271v1.106h.271V6.202zm.13 0h.27v1.106h-.27V6.202zm.671 0h-.271v1.106h.271V6.202zM7.115 7.789h.272v1.105h-.272V7.788zm1.073 0h-.272v1.105h.272V7.788zm-.672 0h.271v1.105h-.271V7.788zm1.072 0h-.271v1.105h.271V7.788zm.13 0h.27v1.105h-.27V7.788zm.671 0h-.271v1.105h.271V7.788z"
          fill="#F7FCFF"
        />
        <path
          d="M10.61 6.202h-.27v1.106h.27V6.202zm.802 0h-.272v1.106h.272V6.202zm-.672 0h.271v1.106h-.271V6.202zm1.072 0h-.271v1.106h.271V6.202zm.13 0h.27v1.106h-.27V6.202zm.671 0h-.271v1.106h.271V6.202zM10.34 7.789h.272v1.105h-.272V7.788zm1.073 0h-.272v1.105h.272V7.788zm-.672 0h.271v1.105h-.271V7.788zm1.072 0h-.271v1.105h.271V7.788zm.13 0h.27v1.105h-.27V7.788zm.671 0h-.271v1.105h.271V7.788z"
          fill="#272727"
        />
        <path
          d="M8.27 4.42c-.38.118-.555 1.115-.512 1.356.042.241.171-.03.171-.03s.264.572.29.726c.028.154.166-.233.166-.233s.457.314.519.665c.061.35.154-.401.154-.401l.104-.018-.066-.371.198.168-.061-.345s.148.194.11-.02l-.058-.332-.052-.292.196-.035s-.779-.956-1.16-.838z"
          fill="#3D58DB"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.305 8.56c-.395.05-.74 1.002-.74 1.247 0 .245.175 0 .175 0s.16.609.16.765c0 .156.203-.2.203-.2s.395.389.395.744c0 .356.222-.368.222-.368h.105v-.376l.165.2v-.35s.113.216.113 0v-.635h.198s-.6-1.077-.996-1.027zM3.496 8.416c.395.05.74 1.002.74 1.247 0 .244-.175 0-.175 0s-.16.608-.16.765c0 .156-.203-.2-.203-.2s-.395.388-.395.744-.222-.368-.222-.368h-.105v-.377l-.165.2v-.35s-.113.217-.113 0v-.634H2.5s.6-1.077.996-1.027z"
        fill="#3D58DB"
      />
    </g>
  </svg>
);
export default FlagSz;
