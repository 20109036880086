import React from 'react';

import type { FlagProps } from '../types';

const FlagHr = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <g mask="url(#prefix__HR_-_Croatia_(Hrvatska)__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#F7FCFF"
      />
      <g mask="url(#prefix__HR_-_Croatia_(Hrvatska)__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v5h20V0H0z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10v5h20v-5H0z"
          fill="#3D58DB"
        />
        <path fill="#E31D1C" d="M6.298 3.998h7.125v2.5H6.298z" />
        <path
          d="M6.658 5.6h6.33c.01.48.014 1.319.014 2.126 0 .521-.002 1.023-.003 1.394l-.002.446v.144a3.071 3.071 0 0 1-.092.37 3.428 3.428 0 0 1-.433.895c-.434.634-1.222 1.286-2.69 1.286s-2.218-.651-2.617-1.279a3.23 3.23 0 0 1-.461-1.261l-.002-.011v-.03l-.001-.122-.006-.443A2267.225 2267.225 0 0 1 6.658 5.6zM6.7 9.7c0-.001 0-.001 0 0z"
          fill="#F7FCFF"
          stroke="#E31D1C"
          strokeWidth={0.703}
        />
        <g mask="url(#prefix__HR_-_Croatia_(Hrvatska)__c)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.562 5.65H7.88v1.339H6.562v-1.34zm2.636 1.339H7.88v1.338H6.562v1.339H7.88v1.339H6.562v1.338H7.88v-1.338h1.318v1.338h1.319v-1.338h1.318v1.338h1.318v-1.338h-1.318V9.666h1.318V8.327h-1.318V6.99h1.318V5.65h-1.318v1.34h-1.318V5.65H9.198v1.34zm0 1.338V6.99h1.319v1.338H9.198zm0 1.339V8.327H7.88v1.339h1.318zm1.319 0v1.339H9.198V9.666h1.319zm0 0V8.327h1.318v1.339h-1.318z"
            fill="#F50100"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.533 5.102s.408-.218.756-.33c.348-.113.622-.174.622-.174L7.394 2.69l-1.036-.387-.587.968.762 1.83z"
          fill="#56C6F5"
          stroke="#fff"
          strokeWidth={0.5}
        />
        <path
          d="m6.363 4.057.12-.058c.122.263.284.345.507.262.227-.085.32-.245.286-.5l.131-.016c.04.315-.086.535-.372.641-.29.109-.521-.007-.672-.33z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m6.645 3.464-.114.069a.034.034 0 0 1-.05-.036l.025-.13-.116-.065a.034.034 0 0 1 .005-.061l.126-.043-.003-.133c0-.03.034-.046.057-.027l.1.088.114-.069a.034.034 0 0 1 .05.036l-.025.13.116.064a.034.034 0 0 1-.005.062l-.126.043.003.133c0 .03-.034.046-.057.026l-.1-.087z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.088 4.422s.348-.03.713-.024c.365.008.675.01.675.01l.178-1.88-.783-.708-.87.724.087 1.878z"
          fill="#56C6F5"
          stroke="#fff"
          strokeWidth={0.5}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.844 4.116c.301 0 .33-.326.33-.548-.077-.123-.061-.21-.308-.21-.248 0-.276.074-.348.21 0 .222.024.548.326.548zm.339-.92c.302 0 .33-.326.33-.548-.077-.123-.061-.21-.308-.21s-.275.074-.348.21c0 .222.025.548.326.548zm-.659 0c.301 0 .33-.326.33-.548-.077-.123-.061-.21-.308-.21s-.276.074-.348.21c0 .222.024.548.326.548z"
          fill="#FECA00"
        />
        <path
          d="m7.643 4.627.072.244.243-.077h.004l.012-.005.047-.014a7.37 7.37 0 0 1 .16-.046c.13-.036.279-.072.383-.087a5.6 5.6 0 0 1 .514-.043l.042-.002h.014l.262-.012-.022-.26-.158-1.828-.01-.115-.094-.067-.852-.605-.175-.125-.15.155-.774.803-.1.104.04.14.542 1.84z"
          fill="#2E42A5"
          stroke="#fff"
          strokeWidth={0.5}
        />
        <g mask="url(#prefix__HR_-_Croatia_(Hrvatska)__d)" fill="#C51918">
          <path d="m7.773 4.149-.157-.37c.336-.146.904-.237 1.502-.272l.023.401c-.555.033-1.081.115-1.368.24zm-.264-.892-.157-.37c.336-.146 1.059-.266 1.657-.3l.023.4c-.556.033-1.236.144-1.523.27z" />
        </g>
        <path
          d="m11.988 4.621-.068.252-.248-.079h-.004l-.013-.005-.047-.014a7.505 7.505 0 0 0-.16-.046 3.4 3.4 0 0 0-.382-.087 5.604 5.604 0 0 0-.515-.043l-.042-.002H10.495l-.261-.012.022-.26.158-1.828.01-.115.094-.067.852-.605.18-.128.15.163.729.8.092.1-.035.133-.498 1.843z"
          fill="#2E42A5"
          stroke="#fff"
          strokeWidth={0.5}
        />
        <g
          mask="url(#prefix__HR_-_Croatia_(Hrvatska)__e)"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path
            d="M11.499 4.042s.183-.103.21-.275c.026-.173.31-.385.168-.36-.142.026-.06.045-.266 0-.206-.045-.472-.074-.472-.201 0-.126.132-.227.024-.227-.108 0-.34-.12-.293-.028.047.091-.106-.012-.03.178.077.19-.016.381.03.432.047.05-.03.297-.03.297s.028.092.077-.046c.05-.137.157-.233.103-.116-.053.116-.122.394-.06.346a.434.434 0 0 0 .121-.23c0-.045.155-.224.155-.089s-.146.241-.073.241c.072 0 .113.029.159-.106s.157-.224.202-.135c.046.09.036.085-.025.202s0 .117 0 .117z"
            fill="#FECA00"
          />
          <path
            d="m11.067 3.042-.068-.115c.122-.076.235-.096.334-.053a.82.82 0 0 1 .167.103l.012.008c.092.064.14.09.17.09v.134c-.068 0-.127-.032-.245-.114l-.012-.008a.818.818 0 0 0-.143-.09c-.055-.023-.125-.01-.215.045zm-.216.873a.07.07 0 0 0 .07-.07.07.07 0 0 0-.07-.07.07.07 0 0 0-.07.07c0 .039.03.07.07.07zm.202.064a.07.07 0 0 1-.07.07.07.07 0 0 1-.07-.07.07.07 0 0 1 .07-.07.07.07 0 0 1 .07.07zm.166.064a.07.07 0 0 0 .07-.07.07.07 0 0 0-.07-.069.07.07 0 0 0-.07.07c0 .038.031.07.07.07zm.29.042a.07.07 0 0 1-.07.07.07.07 0 0 1-.07-.07.07.07 0 0 1 .07-.07.07.07 0 0 1 .07.07z"
            fill="#C51918"
          />
        </g>
        <path
          d="m13.431 5.154-.108.248-.239-.127-.003-.002-.013-.007a5.813 5.813 0 0 0-.698-.304 8.06 8.06 0 0 0-.556-.157l-.034-.008-.008-.002h-.002l-.26-.058.073-.255.529-1.86.034-.121.119-.045L13.3 2.07l.194-.072.108.177.587.967.067.11-.052.12-.774 1.783z"
          fill="#56C6F5"
          stroke="#fff"
          strokeWidth={0.5}
        />
        <g mask="url(#prefix__HR_-_Croatia_(Hrvatska)__f)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m13.205 3.18-.09.1a.034.034 0 0 1-.058-.02l-.014-.132-.13-.027a.034.034 0 0 1-.013-.06l.108-.078-.041-.127a.034.034 0 0 1 .046-.041l.121.054.089-.099a.034.034 0 0 1 .059.02l.013.132.13.027a.034.034 0 0 1 .013.06l-.107.078.041.127a.034.034 0 0 1-.046.041l-.121-.054z"
            fill="#FECA00"
          />
          <path
            d="m11.872 4.055.133-.518c.588.155 1.2.354 1.65.546l-.205.494a12.27 12.27 0 0 0-1.578-.522z"
            fill="#E31D1C"
          />
          <path
            d="m12.052 3.576.031-.13c.564.14 1.163.363 1.598.571l-.056.121a8.832 8.832 0 0 0-1.573-.562zm-.167.534.031-.13a8.11 8.11 0 0 1 1.52.538l-.056.121a7.981 7.981 0 0 0-1.495-.529z"
            fill="#fff"
          />
          <path
            d="m12.14 3.86.12-.055c.05.119.114.145.219.094.247-.12.862.048.862.275 0 .088-.051.142-.13.139a.413.413 0 0 1-.16-.054.82.82 0 0 0-.13-.063l.043-.128a.852.852 0 0 1 .144.07.341.341 0 0 0 .109.04c.001 0-.008.01-.008-.004 0-.1-.5-.237-.674-.152-.174.084-.316.025-.396-.163z"
            fill="#272727"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default FlagHr;
