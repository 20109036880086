import styled from 'styled-components';

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  isolation: isolate;
`;

export default InputGroup;
