import type { Breakpoints } from '../types/types';

const breakpoint: Breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1440,
};

export default breakpoint;
