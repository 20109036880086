import React from 'react';

import type { FlagProps } from '../types';

const FlagVu = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h20v15H0V0z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v5h20V0H0z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10v5h20v-5H0z"
        fill="#5EAA22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0-1.25 10.418 7.5 0 16.25v-17.5z"
        fill="#272727"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={-2}
        y={-5.097}
        width={24}
        height={25}
        fill="#000"
      >
        <path fill="#fff" d="M-2-5.097h24v25H-2z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0-2.097 10.88 6.25H20v2.5h-9.27L0 16.757v-2.794L8.75 7.5 0 .959v-3.056z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0-2.097 10.88 6.25H20v2.5h-9.27L0 16.757v-2.794L8.75 7.5 0 .959v-3.056z"
        fill="#FFD018"
      />
      <path
        d="m10.88 6.25-.76.992.336.258h.424V6.25zM0-2.097l.76-.992-2.01-1.542v2.534H0zM20 6.25h1.25V5H20v1.25zm0 2.5V10h1.25V8.75H20zm-9.27 0V7.5h-.414l-.333.248.748 1.002zM0 16.757h-1.25v2.492l1.998-1.49L0 16.757zm0-2.794-.743-1.006-.507.375v.63H0zM8.75 7.5l.743 1.005 1.353-.999-1.348-1.007L8.75 7.5zM0 .959h-1.25v.626l.502.375.748-1zm11.641 4.3L.761-3.09-.761-1.105l10.88 8.347 1.522-1.984zM20 5h-9.12v2.5H20V5zm1.25 3.75v-2.5h-2.5v2.5h2.5zM10.73 10H20V7.5h-9.27V10zM.749 17.759l10.73-8.007-1.495-2.004-10.73 8.007L.747 17.76zm-1.998-3.796v2.794h2.5v-2.794h-2.5zm9.257-7.468-8.75 6.462 1.486 2.011 8.75-6.463-1.486-2.01zM-.748 1.96l8.75 6.541L9.498 6.5.748-.042-.748 1.96zm-.502-4.057V.96h2.5v-3.056h-2.5z"
        fill="#272727"
        mask="url(#prefix__b)"
      />
      <path
        d="m3.046 10.48.001-.988c.88-.194 1.464-.446 1.747-.75.7-.862.235-2.88-1.201-2.866-.711 0-1.447.339-1.447 1.136 0 .991.204 1.415.901 1.415.693 0 .948.037 1.404-.585l.496.153c-.49.667-1.15 1.193-1.9 1.193-1.24 0-1.913-.933-1.913-2.013 0-1.334 1.082-2.267 2.387-2.267.974 0 2.514 1.192 2.514 2.267 0 1.074 0 1.567-.845 2.317-.34.303-1.183.842-2.144.988z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12 6.175a.09.09 0 0 0-.11.063l-.034.13-.005-.007a.09.09 0 0 0-.141.11l.061.079-.103.095a.09.09 0 1 0 .121.13L3 6.693c.055.07.107.14.156.205l-.17.092a.09.09 0 1 0 .084.157l.195-.105.133.179-.234.126a.09.09 0 0 0 .084.157l.257-.138c.069.094.129.177.18.25l-.164.088a.09.09 0 1 0 .084.157l.182-.097c.076.112.115.18.115.198a.09.09 0 1 0 .178 0c0-.046-.047-.14-.145-.293a.088.088 0 0 0 .004-.008l.132-.29a.09.09 0 0 0-.163-.074l-.09.198a13.703 13.703 0 0 0-.198-.276l.094-.206a.09.09 0 1 0-.163-.073l-.052.115a32.747 32.747 0 0 0-.09-.12l.126-.279a.09.09 0 1 0-.162-.074l-.087.19a57.997 57.997 0 0 0-.172-.225l.07-.262a.09.09 0 0 0-.064-.11z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72 6.175a.09.09 0 0 1 .11.063l.033.13.006-.007a.09.09 0 0 1 .14.11l-.06.079.103.095a.09.09 0 1 1-.122.13l-.09-.083c-.055.07-.107.14-.157.205l.172.092a.09.09 0 1 1-.085.157l-.195-.105-.134.179.235.126a.09.09 0 0 1-.084.157l-.258-.138-.18.25.165.088a.09.09 0 0 1-.085.157l-.181-.097c-.076.112-.115.18-.115.198a.09.09 0 1 1-.178 0c0-.046.047-.14.145-.293A.091.091 0 0 1 2.9 7.66l-.132-.29a.09.09 0 0 1 .162-.074l.09.198.198-.276-.093-.206a.09.09 0 1 1 .162-.073l.053.115.09-.12-.127-.279a.09.09 0 0 1 .163-.074l.087.19.172-.225-.07-.262a.09.09 0 0 1 .064-.11z"
        fill="#FFD018"
      />
    </g>
  </svg>
);
export default FlagVu;
