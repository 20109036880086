enum Vendor {
  footprint = 'footprint',
  idology = 'idology',
  socure = 'socure',
  lexisNexis = 'lexis_nexis',
  experian = 'experian',
  twilio = 'twilio',
}

export default Vendor;
