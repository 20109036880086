import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoStore40 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.873 7.864a5.585 5.585 0 0 1 5.084-3.274H29.03c2.19 0 4.179 1.28 5.085 3.274l1.031 2.27c.074.162.14.327.198.495.19.28.303.62.303.984 0 .148-.005.296-.013.444.008.129.013.258.013.387v17.388c0 3.085-2.5 5.585-5.585 5.585H9.926a5.585 5.585 0 0 1-5.585-5.585V12.444c0-.126.004-.253.012-.378a7.648 7.648 0 0 1-.013-.453c0-.366.112-.706.305-.987.057-.167.123-.331.196-.493l1.032-2.269ZM32.118 12.1c-.079.672-.319 1.276-.668 1.713-.396.496-.977.846-1.868.846-1.756 0-2.99-1.443-3.043-2.943l.001-.102a1.75 1.75 0 0 0-1.726-1.75h-.026a1.75 1.75 0 0 0-1.75 1.86c-.022.8-.288 1.538-.723 2.055-.422.502-1.066.88-2.068.88-2.03 0-3.297-1.596-3.297-3.045a1.75 1.75 0 0 0-3.501 0c0 1.536-1.249 3.045-3.044 3.045-.892 0-1.473-.35-1.869-.846-.348-.437-.588-1.039-.667-1.71.03-.179.083-.354.158-.52l1.032-2.27a2.085 2.085 0 0 1 1.898-1.222H29.03c.818 0 1.56.478 1.899 1.222l1.031 2.27c.075.165.128.339.158.517Zm-7.235 4.06c1.139 1.198 2.753 1.998 4.699 1.998a6.02 6.02 0 0 0 2.565-.548v12.223a2.085 2.085 0 0 1-2.085 2.085h-4.005v-4.964c0-3.084-2.5-5.585-5.585-5.585h-.959a5.585 5.585 0 0 0-5.585 5.585v4.965H9.926a2.085 2.085 0 0 1-2.085-2.086V17.61c.75.35 1.61.547 2.564.547 2.02 0 3.684-.863 4.828-2.139 1.2 1.284 2.94 2.14 5.014 2.14 1.96 0 3.546-.774 4.636-2Zm-7.455 10.794c0-1.151.933-2.085 2.085-2.085h.959c1.152 0 2.085.934 2.085 2.085v4.962h-5.13v-4.962Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoStore40;
