import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoCheck24 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        d="M17.274 7.41a.705.705 0 0 0-.484.214l-7.255 7.255-2.322-2.321a.704.704 0 1 0-.996.997l2.82 2.819a.705.705 0 0 0 .996 0l7.753-7.754a.705.705 0 0 0-.512-1.21Z"
        fill={theme.color[color]}
      />
      <path
        d="m17.274 7.41-.007-.25.007.25Zm-.484.214.177.177.002-.003-.18-.174Zm-7.255 7.255-.177.177.177.176.176-.176-.176-.177Zm-2.322-2.321-.18.173.004.004.176-.177Zm-.996.997.177-.177-.004-.004-.173.18Zm2.82 2.819-.178.177.177-.177Zm.996 0 .177.177-.177-.177Zm7.753-7.754-.174-.179-.002.003.176.176Zm-.52-1.46a.955.955 0 0 0-.656.29l.359.348a.455.455 0 0 1 .313-.138l-.015-.5Zm-.653.287-7.255 7.255.353.354L16.966 7.8l-.353-.354ZM9.71 14.702l-2.32-2.321-.354.354 2.32 2.32.354-.353Zm-2.317-2.317a.956.956 0 0 0-.31-.216l-.199.46a.455.455 0 0 1 .148.102l.36-.346Zm-.31-.216a.955.955 0 0 0-.37-.078l-.004.5c.06 0 .12.013.175.037l.199-.459Zm-.37-.078a.955.955 0 0 0-.37.07l.189.464a.455.455 0 0 1 .177-.034l.005-.5Zm-.37.07a.954.954 0 0 0-.314.21l.353.353a.455.455 0 0 1 .15-.1l-.189-.462Zm-.314.21a.954.954 0 0 0-.21.314l.464.189a.455.455 0 0 1 .1-.15l-.354-.353Zm-.21.314a.955.955 0 0 0-.07.37l.5-.004c0-.06.01-.12.034-.177l-.463-.189Zm-.07.37a.956.956 0 0 0 .078.37l.46-.199a.455.455 0 0 1-.038-.175l-.5.005Zm.078.37c.05.116.124.222.216.31l.346-.36a.455.455 0 0 1-.103-.149l-.459.199Zm.212.306 2.82 2.82.353-.354-2.82-2.82-.353.354Zm2.82 2.82c.179.179.421.28.675.28v-.5a.455.455 0 0 1-.322-.134l-.354.354Zm.675.28c.253 0 .496-.101.675-.28l-.354-.354a.455.455 0 0 1-.321.133v.5Zm.675-.28 7.753-7.754-.353-.353-7.754 7.753.354.354ZM17.96 8.8a.955.955 0 0 0 .271-.496l-.49-.099a.455.455 0 0 1-.129.236l.349.359Zm.271-.496a.955.955 0 0 0-.057-.561l-.46.195a.455.455 0 0 1 .027.267l.49.1Zm-.057-.561a.955.955 0 0 0-.363-.432l-.27.421c.077.05.137.121.173.206l.46-.195Zm-.363-.432a.955.955 0 0 0-.544-.15l.015.5a.455.455 0 0 1 .259.071l.27-.42Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoCheck24;
