import React from 'react';

import type { FlagProps } from '../types';

const FlagUg = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v15h20V0H0z"
        fill="#FECA00"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={15}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0v15h20V0H0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path fill="#272727" d="M0 0h20v2.5H0z" />
        <path fill="#D74800" d="M0 5h20v2.5H0z" />
        <path fill="#272727" d="M0 7.5h20V10H0z" />
        <path fill="#D74800" d="M0 12.5h20V15H0z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 11.25a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m8.55 5.205-.661.217.66.057s.108.261 0 .466a1.046 1.046 0 0 1-.186.202c-.262.25-.692.657-.692 1.32 0 .887 1.713 1.29 1.713 1.29v.421s.198.124-.936.124c-.983 0-.922.373-.888.577.005.032.01.059.01.08 0 .162.472-.161.694-.482.116-.17.604-.16 1.105-.114.04.003.404.777.087 1.176-.133.168-.593.19-.88.204-.146.007-.248.011-.239.032.007.016.094.011.22.003.157-.009.375-.022.577-.003.03.003-.088.106-.208.21-.123.107-.248.215-.213.217.04.002.27-.126.48-.242.174-.096.333-.185.357-.185.159 0 .302.02.427.037.21.029.37.05.471-.037.032-.026-.149-.07-.333-.114-.19-.045-.383-.091-.352-.122.174-.173.101-.535.044-.82a1.497 1.497 0 0 1-.044-.314c.015-.26.289-.123.505-.016.094.046.177.088.222.088.408 0-.098-.517-.377-.8a13.806 13.806 0 0 1-.067-.068c-.141-.147-.042-.127.182-.083.171.034.415.083.68.083.614 0 1.061-.2.906-.739-.103-.356-.664-.477-1.25-.603-.303-.066-.613-.132-.87-.233-.63-.249-.598-.612-.57-.918.005-.06.01-.117.01-.171 0-.333.56-.74.56-.74s-.255-.429-.68-.429c-.425 0-.464.43-.464.43zM9.705 9.11v-.313s.262-.009.354.212c.092.22-.354.101-.354.101z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m8.173 4.195.634.75.328-.076-.158-.759-.804.085z"
          fill="#D74800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m8.995 4.013.041 1.13.541-.134.23-.74-.812-.256z"
          fill="#FFD018"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.486 5.9c.12 0 .216-.095.216-.212a.214.214 0 0 0-.216-.21.214.214 0 0 0-.217.21c0 .117.097.211.217.211zM9.86 4.225l-.63.754.133.31.72-.287-.223-.777zm1.464 5.708s1.108-.495 1.108-1.187c0-.692-1.799-1.446-1.799-1.446s-1.058.243-.581.567c.477.324 1.116.448 1.116.88 0 .43.175.841-.18.841s.336.345.336.345z"
          fill="#D74800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.726 7.174s-.392.069-.373.132c.051.176.587.405.82.564.477.324.93.288 1.266.077.336-.211-1.713-.773-1.713-.773z"
          fill="#FFFDFF"
        />
      </g>
    </g>
  </svg>
);
export default FlagUg;
