import React from 'react';

import type { FlagProps } from '../types';

const FlagMp = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h21.25v15H0V0z"
        fill="#2993EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.883 7.24c0 3.307 2.739 5.99 6.117 5.99s6.117-2.683 6.117-5.99c0-3.308-2.739-5.99-6.117-5.99S3.883 3.932 3.883 7.24zm10.904 0c0 2.588-2.143 4.687-4.787 4.687S5.213 9.828 5.213 7.24c0-2.59 2.143-4.688 4.787-4.688s4.787 2.099 4.787 4.688z"
        fill="#F1F9FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.415 7.24c0 3.02 2.5 5.468 5.585 5.468s5.585-2.448 5.585-5.468-2.5-5.47-5.585-5.47-5.585 2.45-5.585 5.47zm10.904 0c0 2.876-2.381 5.208-5.319 5.208S4.68 10.116 4.68 7.24c0-2.877 2.383-5.21 5.32-5.21s5.32 2.332 5.32 5.209z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.38c-.067.14-.101.337-.101.594 0 .256-.1.512-.298.77a6.003 6.003 0 0 1-.055-.77c0-.23.151-.429.454-.594zm1.398.212c-.097.12-.175.305-.232.555-.058.25-.213.478-.464.683a6.01 6.01 0 0 1 .12-.762c.051-.225.243-.383.576-.476zm.96 1.013c.117-.228.236-.39.36-.483-.345.01-.57.117-.675.323a5.997 5.997 0 0 0-.3.71c.294-.138.499-.322.615-.55zm1.511.322c-.14.064-.294.194-.458.39-.165.196-.406.33-.723.398.153-.24.304-.448.452-.625.148-.177.391-.23.73-.163zm.399 1.32c.204-.154.382-.246.534-.276-.314-.142-.563-.144-.747-.005a5.998 5.998 0 0 0-.582.507c.325.004.59-.071.794-.225zm1.199.963c-.155-.008-.35.039-.586.139s-.511.109-.825.027a6 6 0 0 1 .687-.352c.212-.09.453-.028.724.186zm-.247 1.347c.253-.045.453-.046.602-.004-.215-.27-.436-.384-.663-.344a6.07 6.07 0 0 0-.749.188c.288.151.558.204.81.16zM3.803 6.106c.125.09.314.158.566.203.253.044.488.186.707.427a6.001 6.001 0 0 1-.768-.08c-.227-.04-.395-.223-.505-.55zm.96-.99c-.236-.1-.405-.208-.506-.324.033.343.156.56.368.65.212.09.456.173.73.25-.158-.283-.356-.475-.592-.575zm.254-1.508c.07.138.208.284.413.438.205.155.35.388.436.701a6.001 6.001 0 0 1-.649-.419c-.184-.138-.25-.378-.2-.72zm1.311-.453c-.165-.196-.266-.37-.304-.52-.126.322-.114.57.034.747.148.176.327.361.537.554-.013-.324-.102-.585-.267-.781zm.905-1.239c.003.155.063.346.18.575.116.228.144.502.083.82a6.002 6.002 0 0 1-.398-.66c-.105-.205-.06-.45.135-.735zm1.393.175c-.058-.25-.07-.45-.035-.601-.258.229-.361.455-.31.68.052.224.128.47.228.738.135-.295.174-.568.117-.817z"
        fill="#FDDA33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.116 3.594c-.229 0-.4.212-.35.436l.241 1.104a.365.365 0 0 0 .16.228.358.358 0 0 0-.136.241l-.867 7.279a.332.332 0 0 0 .16.33c.327.187 1.035.538 1.742.538.708 0 1.416-.35 1.742-.537a.332.332 0 0 0 .16-.331l-.866-7.279a.359.359 0 0 0-.137-.241c.08-.05.14-.13.16-.228l.242-1.104a.359.359 0 0 0-.35-.436h-1.9z"
        fill="#A7A7A7"
      />
      <path
        d="M4.075 7.508a.2.2 0 0 1 .249-.13.195.195 0 0 1 .132.244c-.056.179-.056.442.006.787a1.754 1.754 0 0 0 .133.435l.05.105a.978.978 0 0 1 .085.427c0 .108-.09.195-.2.195a.197.197 0 0 1-.199-.195.588.588 0 0 0-.053-.272 8.385 8.385 0 0 0-.046-.099 2.007 2.007 0 0 1-.163-.529c-.072-.4-.072-.721.006-.968z"
        fill="#73BE4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.133 10.112-2.572 1.752.908-2.936-2.497-1.853 3.132-.063 1.029-2.897 1.029 2.897 3.132.063-2.497 1.853.907 2.936-2.571-1.752z"
        fill="#F7FCFF"
      />
      <path
        d="M15.975 7.768a.2.2 0 0 0-.25-.13.195.195 0 0 0-.132.245c.056.178.056.442-.006.787a1.756 1.756 0 0 1-.133.434l-.049.106a.966.966 0 0 0-.086.426c0 .108.09.196.2.196.11 0 .2-.088.2-.196 0-.118.014-.184.052-.271l.046-.1a1.971 1.971 0 0 0 .163-.528c.072-.4.072-.722-.005-.969z"
        fill="#73BE4A"
      />
      <path
        d="M10.038 1.967s.728.324.413.835m.119-.835s.728.324.413.835"
        stroke="#73BE4A"
        strokeWidth={0.625}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.787 3.973a.171.171 0 0 1 .248-.061c.084.057.11.178.056.269-.1.175-.154.415-.154.724 0 .108-.08.195-.18.195-.098 0-.179-.087-.179-.195 0-.377.069-.688.21-.932z"
        fill="#73BE4A"
      />
      <path
        d="M5.505 5.758c0 .108-.08.195-.18.195-.098 0-.179-.087-.179-.195V5.2c0-.393.062-.703.192-.93a.171.171 0 0 1 .248-.062c.084.058.11.178.057.27-.09.155-.138.396-.138.722v.557zm9.173-.261c0 .108.08.196.18.196.098 0 .179-.088.179-.196v-.556c0-.394-.062-.704-.192-.93a.171.171 0 0 0-.248-.062.206.206 0 0 0-.057.27c.09.154.138.395.138.722v.556z"
        fill="#73BE4A"
      />
      <path
        d="M15.21 6.409c0 .108.08.195.18.195.098 0 .179-.087.179-.195v-.556c0-.394-.062-.704-.192-.93a.171.171 0 0 0-.248-.063.206.206 0 0 0-.057.27c.09.155.138.396.138.723v.556z"
        fill="#73BE4A"
      />
    </g>
  </svg>
);
export default FlagMp;
