import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoUsers16 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.835 2.29a1.763 1.763 0 1 0 0 3.525 1.763 1.763 0 0 0 0-3.525ZM2.773 4.053a3.063 3.063 0 1 1 6.125 0 3.063 3.063 0 0 1-6.125 0Zm-.35 9.54c.127-.62.389-1.431.913-2.076.502-.618 1.265-1.112 2.5-1.112 1.234 0 1.997.494 2.499 1.112.523.645.785 1.456.911 2.077a.076.076 0 0 1 0 .037.072.072 0 0 1-.016.029.177.177 0 0 1-.133.05H2.573a.177.177 0 0 1-.132-.05.072.072 0 0 1-.017-.029.076.076 0 0 1 0-.037Zm3.412-4.488c-1.651 0-2.776.69-3.509 1.592-.71.876-1.03 1.92-1.176 2.638-.193.95.583 1.676 1.423 1.676h6.524c.84 0 1.617-.725 1.423-1.676-.146-.719-.465-1.762-1.176-2.638-.733-.901-1.857-1.592-3.509-1.592Zm5.483 0a.65.65 0 1 0 0 1.3c.645 0 1.11.317 1.476.837.38.538.619 1.252.762 1.896.057.26-.14.573-.547.573h-.814a.65.65 0 1 0 0 1.3h.814c1.094 0 2.084-.947 1.816-2.155-.159-.714-.444-1.621-.969-2.364-.538-.762-1.36-1.387-2.538-1.387ZM10.441.99a.65.65 0 1 0 0 1.3c.894 0 1.543.704 1.543 1.763 0 1.058-.649 1.762-1.543 1.762a.65.65 0 1 0 0 1.3c1.77 0 2.843-1.456 2.843-3.062 0-1.607-1.073-3.063-2.843-3.063Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoUsers16;
