import type { Elevations } from '../types/types';

const elevations: Elevations = {
  0: 'none',
  1: 'var(--fp-elevation-1)',
  2: 'var(--fp-elevation-2)',
  3: 'var(--fp-elevation-3)',
};

export default elevations;
