import React from 'react';

import type { FlagProps } from '../types';

const FlagAd = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0h10v15H5V0z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h5v15H0V0z"
        fill="#2E42A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0h5v15h-5V0z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.187 12.14c.138-.04.257-.28.257-.28s.09.071.233.143c.143.072.292-.142.292-.142s.203 0 .292-.24c.09-.24-.178-.342-.178-.342s.312-.328.839-.328c.526 0 .936 0 1.28-.146s.678-.396.803-.917c.124-.521-.271-.85-.271-.85s.088.029.27-.121c.183-.15-.111-.631-.27-.631-.159 0-.177.089-.177.089s-.098-.085-.232-.374c-.135-.29.134-.543.134-.543s.147.084.275-.055c.128-.139-.067-.352-.067-.352s.618-.21.529-1c-.09-.79-.904-.745-.904-.745s.025-.131-.09-.206c-.115-.075-.2.075-.2.075-.09-.209.174-.494.385-.722.066-.07.126-.136.17-.192.182-.235.068-.527-.6-.845-.668-.318-1.033.11-1.086.261-.053.152.154.245.154.245a.734.734 0 0 1-.719.103c-.223-.103-.124-.414-.078-.5l.02-.033c.063-.106.194-.327.058-.542-.153-.242-.417-.242-.629-.105-.18.118-.438.18-.508.196a1.726 1.726 0 0 1-.51-.196c-.21-.137-.475-.137-.628.105-.136.215-.004.436.058.542l.02.034c.046.085.145.396-.078.499a.734.734 0 0 1-.719-.103s.208-.093.154-.245c-.053-.151-.418-.579-1.086-.26-.668.317-.782.609-.6.844.044.056.104.121.17.192.21.228.475.513.385.722 0 0-.085-.15-.2-.075s-.09.206-.09.206-.814-.045-.904.745c-.089.79.529 1 .529 1s-.195.213-.067.352c.128.14.275.055.275.055s.269.254.135.543c-.135.289-.233.374-.233.374s-.018-.09-.177-.09-.453.482-.27.632c.182.15.27.122.27.122s-.395.328-.27.849c.124.52.458.771.802.917.344.146.754.146 1.28.146.527 0 .839.328.839.328s-.267.102-.178.342c.09.24.292.24.292.24s.149.214.292.142c.143-.072.233-.142.233-.142s.119.24.257.279v.005l.018-.001.019.001v-.005z"
        fill="#BF9937"
      />
      <path
        d="m7.569 9.999.066-.16c.754.324 1.613.486 2.577.486.963 0 1.799-.161 2.508-.484l.07.157c-.733.333-1.592.5-2.578.5-.985 0-1.867-.166-2.643-.5z"
        fill="#805440"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.799 4.52h4.713v4.394s-.001.388-.411.557c-.41.17-.892.11-.892.11s-.462-.1-.705 0c-.15.06-.258.09-.32.104v.012l-.029-.005a.502.502 0 0 1-.03.005v-.012a2.137 2.137 0 0 1-.318-.105c-.244-.099-.705 0-.705 0s-.482.061-.892-.109a.638.638 0 0 1-.411-.557V4.52z"
        fill="#BF9937"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={7}
        y={4}
        width={6}
        height={6}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.799 4.52h4.713v4.394s-.001.388-.411.557c-.41.17-.892.11-.892.11s-.462-.1-.705 0c-.15.06-.258.09-.32.104v.012l-.029-.005a.502.502 0 0 1-.03.005v-.012a2.137 2.137 0 0 1-.318-.105c-.244-.099-.705 0-.705 0s-.482.061-.892-.109a.638.638 0 0 1-.411-.557V4.52z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path fill="#D00F3A" d="M7.733 4.465h2.285v2.438H7.733z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.272 4.465h2.37v2.438h-2.37V4.465zm0 2.709h2.285V9.7h-2.285V7.174z"
          fill="#FEDF00"
        />
        <path fill="#FEDF00" d="M7.733 7.174h2.285v2.528H7.733z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.965 8.312s.106-.108.106-.363c0-.254.09-.422-.106-.422h-.649c-.187 0-.09.113-.279 0-.188-.113-.34-.077-.34 0s.19.043.19.2c0 .156.072.02.072.302 0 .283.04.235.059.235.02 0 .02-.05.02-.125 0-.074-.01-.373.042-.281.052.091.078.048.104.153.025.104.095.206.047.253-.047.048-.143.088-.072.088.072 0 .176-.12.124-.323-.052-.202.065-.018.065-.018s.276.06.294-.023c.018-.084.043.08.118.08s-.072.17-.134.196c-.062.027 0 .048 0 .048s.058.047.096 0c.038-.048.175-.203.175-.283 0-.08.088-.032.088.04 0 .07-.082.324-.051.283l.03-.04zm0 .993s.106-.108.106-.362c0-.255.09-.423-.106-.423h-.649c-.187 0-.09.113-.279 0-.188-.113-.34-.077-.34 0s.19.043.19.2c0 .156.072.02.072.302 0 .283.04.235.059.235.02 0 .02-.05.02-.125 0-.074-.01-.373.042-.281.052.092.078.048.104.153.025.104.095.206.047.253-.047.048-.143.088-.072.088.072 0 .176-.12.124-.323-.052-.202.065-.018.065-.018s.276.061.294-.023c.018-.084.043.08.118.08s-.072.17-.134.196c-.062.027 0 .048 0 .048s.058.047.096 0c.038-.048.175-.203.175-.283 0-.08.088-.031.088.04 0 .07-.082.324-.051.283l.03-.04z"
          fill="#D00F3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.881 6.436c0 .103-.052.182-.172.303l-.048.048a1.15 1.15 0 0 0-.042.044c-.102.116-.28.016-.327-.096a2.914 2.914 0 0 0-.101-.183l-.003-.005-.035-.06-.031-.053a3.675 3.675 0 0 1-.04-.072c-.005-.007-.029.001-.07.054-.016.02.016.09.12.2l-.059.065c-.144-.14-.213-.23-.213-.294 0-.105.084-.161.242-.161.099 0 .151.07.23.245l.02.045a.8.8 0 0 0 .095.172c.036.046.058.044.095-.003s.071-.119.122-.249a2.89 2.89 0 0 1 .014-.035c.011-.027.016-.04.013-.042-.003-.002-.01.002-.026.01l-.02.011-.039.02-.358-.861.678-.824V6.43l-.043-.04a.495.495 0 0 0-.002.045zm.049-.002-.004.003V6.43l.004.003zm.003-.003v.006l-.003-.003.003-.003zm0-1.716.679.824-.359.862-.04-.021c-.027-.014-.041-.024-.045-.02-.004.003.007.024.027.076.051.13.085.202.122.249.037.047.059.049.095.003a.8.8 0 0 0 .095-.172l.02-.045c.079-.175.132-.245.23-.245.158 0 .242.056.242.161 0 .063-.07.154-.213.294l-.058-.065c.103-.11.135-.18.12-.2-.042-.053-.066-.061-.07-.054a3.699 3.699 0 0 1-.04.072l-.032.053-.034.06-.004.005a2.55 2.55 0 0 0-.101.183c-.046.112-.225.212-.327.096a1.15 1.15 0 0 0-.042-.044l-.048-.048c-.12-.121-.172-.2-.172-.303 0-.018 0-.033-.002-.044l-.043.039V4.715zm0 0L8.93 4.71l.003-.004v.008zM8.93 4.71l-.004.004v-.008l.004.004zm-.042 1.654.001-.002-.001.002z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.924 6.281V4.889l.588.666-.302.726c-.111-.039-.15.043-.093.19.034.087.061.15.088.198-.1-.101-.142-.165-.142-.233a.262.262 0 0 0-.019-.118c-.006-.012-.059-.023-.093-.03l-.027-.007zm-.275 0-.302-.726.574-.66v1.391a.237.237 0 0 1-.026.006c-.03.006-.074.014-.08.026a.262.262 0 0 0-.018.118c0 .068-.042.132-.142.233.026-.048.053-.111.087-.198.058-.147.019-.229-.093-.19z"
          fill="#FEDF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.24 7.174h-.253V9.7h.254V7.174zm.509 0h-.254V9.7h.254V7.174zm.254 0h.254V9.7h-.254V7.174zm.761 0H9.51V9.7h.254V7.174zm1.101-2.709h-.254v2.528h.254V4.465zm.507 0h-.254v2.528h.254V4.465zm.254 0h.254v2.528h-.254V4.465zm.762 0h-.254v2.528h.254V4.465z"
          fill="#D00F3A"
        />
      </g>
    </g>
  </svg>
);
export default FlagAd;
