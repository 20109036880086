import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoCheckSmall24 = ({
  color = 'primary',
  className,
  testID,
}: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        d="M16.395 8.18a.587.587 0 0 0-.404.178l-6.046 6.046-1.934-1.934a.587.587 0 1 0-.83.83l2.35 2.35a.588.588 0 0 0 .83 0l6.461-6.461a.587.587 0 0 0-.427-1.009Z"
        fill={theme.color[color]}
      />
      <path
        d="m16.395 8.18-.007-.25.007.25Zm-.404.178.177.177.003-.003-.18-.174Zm-6.046 6.046-.176.177.176.177.177-.177-.177-.177ZM8.011 12.47l-.18.173.003.004.177-.177Zm-.83.83.176-.176-.003-.004-.173.18Zm2.35 2.35-.178.177.177-.177Zm.83 0 .176.177-.176-.177Zm6.461-6.461-.174-.18-.003.003.177.177Zm-.434-1.259a.837.837 0 0 0-.576.254l.359.348a.337.337 0 0 1 .232-.102l-.015-.5Zm-.573.251-6.046 6.046.353.354 6.046-6.046-.353-.354Zm-5.693 6.046-1.934-1.934-.354.354L9.77 14.58l.353-.354Zm-1.93-1.93a.838.838 0 0 0-.272-.19l-.199.46a.337.337 0 0 1 .11.076l.36-.346Zm-.272-.19a.839.839 0 0 0-.324-.068l-.005.5c.045 0 .09.01.13.028l.199-.46Zm-.324-.068a.838.838 0 0 0-.325.062l.189.463a.338.338 0 0 1 .13-.025l.006-.5Zm-.325.062a.837.837 0 0 0-.276.183l.354.354a.339.339 0 0 1 .11-.074l-.188-.463Zm-.276.183a.837.837 0 0 0-.183.276l.463.19a.338.338 0 0 1 .074-.112l-.354-.354Zm-.183.276a.837.837 0 0 0-.062.325l.5-.005c0-.045.008-.09.025-.13l-.463-.19Zm-.062.325a.838.838 0 0 0 .069.324l.459-.198a.337.337 0 0 1-.028-.13l-.5.004Zm.069.324a.837.837 0 0 0 .188.272l.347-.361a.336.336 0 0 1-.076-.11l-.46.199Zm.185.268 2.35 2.35.353-.354-2.35-2.35-.353.354Zm2.35 2.35c.156.157.37.245.591.245v-.5a.337.337 0 0 1-.238-.099l-.354.354Zm.591.245a.837.837 0 0 0 .592-.245l-.353-.354a.337.337 0 0 1-.239.099v.5Zm.592-.245L17 9.365l-.354-.353-6.461 6.461.354.354Zm6.46-6.46a.838.838 0 0 0 .236-.434l-.49-.099a.338.338 0 0 1-.095.175l.348.359Zm.236-.434a.838.838 0 0 0-.05-.492l-.46.195a.337.337 0 0 1 .02.198l.49.1Zm-.05-.492a.838.838 0 0 0-.318-.378l-.27.42c.057.037.102.09.128.153l.46-.195Zm-.318-.378a.837.837 0 0 0-.477-.133l.015.5a.337.337 0 0 1 .192.053l.27-.42Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoCheckSmall24;
