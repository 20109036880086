import type { Dropdown } from '../../types/components';

const dropdown: Dropdown = {
  bg: 'var(--fp-base-dropdown-base-bg-primary)',
  borderColor: 'var(--fp-base-dropdown-base-border)',
  borderWidth: 'var(--fp-border-width-default)',
  elevation: 'var(--fp-base-dropdown-elevation)',
  borderRadius: 'var(--fp-base-dropdown-border-radius)',
  colorPrimary: 'var(--fp-base-dropdown-base-text-primary)',
  colorSecondary: 'var(--fp-base-dropdown-base-text-secondary)',
  hover: {
    bg: 'var(--fp-base-dropdown-base-bg-primary-hover)',
  },
  footer: {
    bg: 'var(--fp-base-dropdown-base-bg-address-footer)',
  },
};

export default dropdown;
