import React from 'react';

import type { FlagProps } from '../types';

const FlagCx = ({ className, testID }: FlagProps) => (
  <svg
    width={20}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testID}
    className={className}
    aria-hidden="true"
  >
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path fill="#fff" d="M0 0h20v15H0z" />
    </mask>
    <g mask="url(#prefix__a)" fillRule="evenodd" clipRule="evenodd">
      <path d="M0 0h20v15H0V0z" fill="#5EAA22" />
      <path d="m0 0 20 15H0V0z" fill="#4141DB" />
      <path
        d="m3.75 5.625-.625.458.084-.77L2.5 5l.709-.313-.084-.77.625.458.625-.458-.084.77L5 5l-.709.313.084.77-.625-.458zM2.5 9.375l-.625.458.084-.77-.709-.313.709-.313-.084-.77.625.458.625-.458-.084.77.709.313-.709.313.084.77-.625-.458zm2.5 3.75-.625.457.084-.77-.709-.312.709-.313-.084-.77.625.458.625-.457-.084.77.709.312-.709.313.084.77L5 13.124zm.625-5.937-.313.228.042-.385L5 6.875l.354-.156-.042-.385.313.229.313-.23-.042.386.354.156-.354.156.042.385-.313-.229z"
        fill="#fff"
      />
      <path
        d="M13.476 7.64c-.234-.244 1.869-.816 2.086-1.555.29-.643.096-1.164-.995-1.621-1.091-.458-2.194-1.343-.926-1.343 1.268 0 1.268.181 1.609.715.34.533 1.077.647 1.093 0 0-1.104.104-1.252-.787-2.212-.89-.96 2.234.394 1.976 2.04-.259 1.644-.57 1.113-.372 1.39.197.275 1.385-.582 1.237.56-.416.657-1.24 1.634-4.921 2.026z"
        fill="#FECA00"
      />
      <path
        d="M10 10.625a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25z"
        fill="#FECA00"
      />
      <path
        d="M8.096 6.351c.354-.079.839.87 1.714.514.874-.356 1.057-1.035 1.473-.786.415.248.47.847.193 1.148-.277.301-.747.372-.747.753 0 .38-.092 1.737-.271 1.344-.539-.437-.21-1.133-.874-1.344-.663-.211-1.066-.204-1.555-.115-.49.089.329-.245.502-.562.353-.34-.597-.839-.435-.952z"
        fill="#548650"
      />
    </g>
  </svg>
);
export default FlagCx;
