import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoDatabase16 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.444 3.657c.033.033.086.079.168.136.25.173.65.362 1.192.535 1.078.344 2.581.585 4.2.585 1.62 0 3.123-.24 4.2-.585.543-.173.944-.362 1.193-.535.082-.057.134-.103.168-.136a1.217 1.217 0 0 0-.168-.137c-.25-.173-.65-.362-1.193-.535-1.078-.344-2.58-.585-4.2-.585-1.619 0-3.122.24-4.2.585-.542.173-.943.362-1.192.535a1.22 1.22 0 0 0-.168.137Zm.935-2.006C4.613 1.257 6.262 1 8.004 1c1.743 0 3.392.257 4.626.651.613.196 1.158.436 1.566.72.37.257.813.682.813 1.286v8.695c0 .604-.443 1.029-.813 1.286-.408.283-.953.524-1.566.72-1.234.393-2.883.65-4.626.65-1.742 0-3.391-.256-4.625-.65-.613-.196-1.159-.437-1.566-.72-.37-.257-.813-.682-.813-1.286V3.657c0-.604.443-1.029.813-1.286.407-.284.953-.524 1.566-.72ZM2.4 5.28v2.675c.019.026.073.09.212.187.25.173.65.362 1.192.535 1.078.344 2.581.585 4.2.585 1.62 0 3.123-.24 4.2-.585.543-.173.944-.362 1.193-.535a.875.875 0 0 0 .212-.187V5.279c-.295.143-.626.27-.979.383-1.234.394-2.883.651-4.626.651-1.742 0-3.391-.257-4.625-.651a7.58 7.58 0 0 1-.979-.383Zm11.209 4.348c-.295.142-.626.27-.979.383-1.234.394-2.883.65-4.626.65-1.742 0-3.391-.256-4.625-.65a7.576 7.576 0 0 1-.979-.383v2.675c.019.026.073.09.212.187.25.173.65.361 1.192.535 1.078.344 2.581.585 4.2.585 1.62 0 3.123-.241 4.2-.585.543-.174.944-.362 1.193-.535a.877.877 0 0 0 .212-.187V9.627Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoDatabase16;
