import type { BorderRadiuses } from '../types/types';

const borderRadius: BorderRadiuses = {
  none: '0px',
  compact: 'var(--fp-border-radius-compact)',
  default: 'var(--fp-border-radius-default)',
  large: 'var(--fp-border-radius-large)',
  full: 'var(--fp-border-radius-full)',
};

export default borderRadius;
