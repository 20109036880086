import type { ZIndexes } from '../types/types';

const zIndexes: ZIndexes = {
  sticky: 5,
  tooltip: 10,
  dropdown: 10,
  overlay: 15,
  dialog: 20,
  popover: 20,
  drawer: 25,
  toast: 30,
};

export default zIndexes;
