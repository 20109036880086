enum BusinessDataAttribute {
  name = 'name',
  doingBusinessAs = 'dba',
  website = 'website',
  phoneNumber = 'phone_number',
  tin = 'tin',
  beneficialOwners = 'beneficial_owners',
  addressLine1 = 'address_line1',
  addressLine2 = 'address_line2',
  city = 'city',
  state = 'state',
  country = 'country',
  zip = 'zip',
}

export default BusinessDataAttribute;
